export const AUTHENTICATION_TOKEN = "token";
export const USER_ID = "userId";
export const PROPERTY_TO_SAVE = "property_to_save";
export const PROPERTY_TO_LIKE = "property_to_like";
export const SHOW_CONVERSATION_SUCCESS = "show_conversation_success";
export const MESSAGE_REFRESH_TIME = 10000;
export const SIGNIN_MODAL_TYPE = {
  CHAT: "chat",
  PROPERTY: "property",
  FAVORITE: "favorite",
};
export const EMAIL_REGEX = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
export const FAVORITE_BUTTON_ACTION = {
  LIKE: "like",
  UNLIKE: "unlike",
};

export const UPLOAD_PHOTO_TYPE = {
  PROPERTY_COVER: "propertycoverphotos",
  USER_PROFILE: "userprofilephotos",
};
