import React, { useEffect, useRef, useState } from "react";
import { formatNumber } from "@src/utils/misc.js";

function PropertyProfileEditorTexAreaInput({
  id,
  title,
  value = "",
  onChange,
  content,
  placeholder = "",
}) {
  const inputRef = useRef(null);
  const [inputValue, setInputValue] = useState(value);
  const [textareaHeight, setTextareaHeight] = useState("auto");

  // Sync the prop value with internal state whenever the value changes
  useEffect(() => {
    setInputValue(value);
  }, [value]);

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
    setInputValue(value);
  }, []);

  useEffect(() => {
    const handleViewportResize = () => {
      const viewportHeight = window.visualViewport.height;

      if (viewportHeight < window.innerHeight * 0.7) {
        setTextareaHeight(`${viewportHeight * 0.38}px`);
      } else {
        setTextareaHeight(`${viewportHeight * 0.6}px`);
      }
    };

    if (window.visualViewport) {
      window.visualViewport.addEventListener("resize", handleViewportResize);
    }

    handleViewportResize();

    return () => {
      if (window.visualViewport) {
        window.visualViewport.removeEventListener(
          "resize",
          handleViewportResize,
        );
      }
    };
  }, []);

  const handleInputChange = (e) => {
    setInputValue(e.target.value); // Update internal state
    if (onChange) {
      onChange(e.target.value); // Call the parent onChange handler (Redux action or other)
    }
  };

  return (
    <div className="w-[min(calc(100%-5rem),28rem)]">
      <h1
        className="text-balance p-2 px-8 text-center font-attention text-2xl font-light leading-[130%] text-neutral-600 sm:text-3xl md:pt-8"
        htmlFor={id}
      >
        {title}
      </h1>
      {content && (
        <p className="mt-4 text-center text-sm text-neutral-600">{content}</p>
      )}
      <textarea
        ref={inputRef}
        rows="5"
        onChange={handleInputChange}
        placeholder={placeholder}
        className="mt-7 w-[min(calc(100%-5rem),28rem)] w-full resize-none rounded-md p-3 font-content text-neutral-900 outline-none ring-1 ring-neutral-300 transition-all focus:ring-primary-500"
        id={id}
        value={inputValue}
        style={{ height: textareaHeight }}
      />
    </div>
  );
}

export default PropertyProfileEditorTexAreaInput;
