import {
  useEffect,
  useState,
  useRef,
  useCallback,
  useMemo,
  forwardRef,
  useImperativeHandle,
} from "react";
import { useMapsLibrary } from "@vis.gl/react-google-maps";
import {
  closeSearchPanel,
  setMode,
  openSearchOptionPanel,
  setSearchOptionType,
  setAiSearchString,
  setIsTraditionalSearchingFailure,
  setIsAiSearchingFailure,
  setLastSearchValues,
  closeSearchOptionPanel,
} from "../../store/features/search/slice.js";
import { searchProperties } from "../../store/features/search/service.js";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useSearchParams, useLocation } from "react-router-dom";
import { AnimatePresence, motion, useAnimationControls } from "framer-motion";
import { MdArrowBack, MdSearch, MdArrowForward, MdClose } from "react-icons/md";
import SparkleIcon from "@src/assets/svgs/SparkleIcon";
import RangeSelector from "../RangeSelector.jsx";
import MultiSelectRadioGroup from "../MultiSelectRadioGroup.jsx";
import TextValueDisplay from "../TextValueDisplay.jsx";
import TagsValueDisplay from "../TagsValueDisplay.jsx";
import SearchOptionPanel from "./SearchOptionPanel.jsx";
import SearchArchitecturalStyles from "./SearchArchitecturalStyles.jsx";
import price_range from "../../data/price_range.js";
import square_feet from "../../data/square_feet.js";
import bedroom_options from "../../data/bedroom_options.js";
import architectural_styles from "../../data/architectural_styles.js";
import property_perks from "@src/data/property_perks.js";
import SearchSpinner from "@src/components/searchSpinner/SearchSpinner.jsx";
import { formatGoogleAPIPlace } from "@src/utils/misc.js";
import { IoMdCloseCircle } from "react-icons/io";
import bathroom_options from "@src/data/bathroom_options.js";
import SelectPerks from "@src/components/selectPerks/SelectPerks.jsx";

function SearchPanel() {
  const inputRef = useRef(null);
  const {
    properties,
    newResults,
    isShowingSearchPanel,
    isSearching,
    isTraditionalSearchingFailure,
    isAiSearchingFailure,
    currentMode,
    searchOptionType,
    selectedArchitecturalStyles,
    selectedPropertyPerks,
    aiSearchString,
    currentDisplayProperty,
    lastSearchValues,
  } = useSelector((state) => state.search);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const controls = useAnimationControls();
  const [isSearchingFromPanel, setIsSearchingFromPanel] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const [currentArchitecturalStyles, setCurrentArchitecturalStyles] = useState(
    [],
  );

  const priceRangeValues = price_range.values;
  const squareFeetValues = square_feet.values;
  const bedroomsValues = bedroom_options.values;
  const bathroomValues = bathroom_options.values;
  const architecturalStylesValues = architectural_styles;
  const perksValues = property_perks;

  //New Stuff

  const [localTraditionalSearchString, setLocalTraditionalSearchString] =
    useState(lastSearchValues?.traditionalSearchString || "");

  const [localSelectedPlace, setLocalSelectedPlace] = useState(
    lastSearchValues?.selectedPlace || null,
  );

  const [localPriceRangeInputValues, setLocalPriceRangeInputValues] = useState(
    lastSearchValues?.priceRangeSetting || price_range.defaultValues,
  );

  const [localSquareFootageInputValues, setLocalSquareFootageInputValues] =
    useState(
      lastSearchValues?.squareFootageSetting || square_feet.defaultValues,
    );

  const [localBedroomInputValues, setLocalBedroomInputValues] = useState(
    lastSearchValues?.beds || bedroom_options.defaultValues,
  );

  const [localBathroomInputValues, setLocalBathroomInputValues] = useState(
    lastSearchValues?.baths || bathroom_options.defaultValues,
  );

  const [
    localArchitecturalStyleInputValues,
    setLocalArchitecturalStyleInputValues,
  ] = useState(selectedArchitecturalStyles);

  const [localPropertyPerksInputValues, setLocalPropertyPerksInputValues] =
    useState(selectedPropertyPerks);

  const searchOptions = [
    {
      id: "architectural_styles",
      label: "Architectural Styles",
    },
    {
      id: "perks",
      label: "Perks",
    },
  ];

  if (!lastSearchValues) {
    dispatch(
      setLastSearchValues({
        selectedPlace: null,
        traditionalSearchString: localTraditionalSearchString,
        priceRangeSetting: localPriceRangeInputValues,
        squareFootageSetting: localSquareFootageInputValues,
        beds: localBedroomInputValues,
        baths: localBathroomInputValues,
        selectedArchitecturalStyles: [],
        selectedPropertyPerks: [],
      }),
    );
  }

  const location = useLocation();

  const [currentPropertyPerks, setCurrentPropertyPerks] = useState([]);

  function arraysEqual(a, b) {
    if (a?.length !== b?.length) return false;
    const aSorted = [...a].sort();
    const bSorted = [...b].sort();
    for (let i = 0; i < aSorted.length; i++) {
      if (aSorted[i] !== bSorted[i]) return false;
    }
    return true;
  }

  const isAnySelectorChanged = useMemo(() => {
    const isPriceRangeChanged =
      localPriceRangeInputValues[0] !==
        lastSearchValues?.priceRangeSetting[0] ||
      localPriceRangeInputValues[1] !== lastSearchValues?.priceRangeSetting[1];

    const isSquareFootageChanged =
      localSquareFootageInputValues[0] !==
        lastSearchValues?.squareFootageSetting[0] ||
      localSquareFootageInputValues[1] !==
        lastSearchValues?.squareFootageSetting[1];

    const isBedroomsChanged = !arraysEqual(
      localBedroomInputValues,
      lastSearchValues?.beds,
    );
    const isBathroomsChanged = !arraysEqual(
      localBathroomInputValues,
      lastSearchValues?.baths,
    );

    const isArchitecturalStylesChanged = !arraysEqual(
      localArchitecturalStyleInputValues,
      lastSearchValues?.selectedArchitecturalStyles,
    );
    const isPropertyPerksChanged = !arraysEqual(
      localPropertyPerksInputValues,
      lastSearchValues?.selectedPropertyPerks,
    );

    return (
      isPriceRangeChanged ||
      isSquareFootageChanged ||
      isBedroomsChanged ||
      isBathroomsChanged ||
      isArchitecturalStylesChanged ||
      isPropertyPerksChanged
    );
  }, [
    localPriceRangeInputValues,
    localSquareFootageInputValues,
    localBedroomInputValues,
    localBathroomInputValues,
    localArchitecturalStyleInputValues,
    localPropertyPerksInputValues,
    lastSearchValues,
  ]);

  const handlePriceRangeChange = useCallback((indexes, indexedValues) => {
    setLocalPriceRangeInputValues(indexes);
  }, []);

  const handleSquareFeetChange = useCallback((indexes, indexedValues) => {
    setLocalSquareFootageInputValues(indexes);
  }, []);

  const handleSearchPanelClose = () => {
    dispatch(closeSearchPanel());
    dispatch(closeSearchOptionPanel());
  };

  const handleModeSwitch = (mode) => {
    dispatch(setMode(mode));
  };

  const handleSearchOptionClick = (id) => {
    dispatch(setSearchOptionType(id));
    dispatch(openSearchOptionPanel());
  };

  const handleBedroomChange = (value) => {
    setLocalBedroomInputValues(value);
  };

  const handleBathroomChange = (value) => {
    setLocalBathroomInputValues(value);
  };

  const containerVariants = {
    close: {
      x: "100%",
      transition: {
        type: "easeOut",
        damping: 0,
        duration: 0.125,
      },
    },
    open: {
      x: "0%",
      transition: {
        type: "easeOut",
        damping: 0,
        duration: 0.125,
      },
    },
  };

  const overlayVariants = {
    close: {
      opacity: 0,
      transition: {
        type: "easeOut",
        damping: 0,
        duration: 0.125,
      },
    },
    open: {
      opacity: 1,
      transition: {
        type: "easeOut",
        damping: 0,
        duration: 0.125,
      },
    },
  };

  const onPlaceSelected = (place) => {
    if (!place?.formatted_address) return;
    setLocalSelectedPlace(place);
  };

  const onSearchQueryChange = async (e) => {
    dispatch(setIsTraditionalSearchingFailure(false));
    setLocalTraditionalSearchString(e.target.value);
  };

  useEffect(() => {
    if (isShowingSearchPanel) {
      controls.start("open");
    } else {
      controls.start("close");
    }
  }, [isShowingSearchPanel]);

  useEffect(() => {
    const currentStyles = localArchitecturalStyleInputValues
      .map(
        (style) =>
          architecturalStylesValues.find(({ id }) => id === style).label,
      )
      .sort()
      .join(", ");
    setCurrentArchitecturalStyles(currentStyles);

    const currentPerks = localPropertyPerksInputValues
      .map((perk) => perksValues.find(({ id }) => id === perk))
      .sort((a, b) => a.text.localeCompare(b.text));
    setCurrentPropertyPerks(currentPerks);
  }, [localArchitecturalStyleInputValues, localPropertyPerksInputValues]);

  useEffect(() => {
    if (properties.length > 1 && isShowingSearchPanel && isSearchingFromPanel) {
      console.log("ran 1");
      // Update lastSearchValues
      dispatch(
        setLastSearchValues({
          selectedPlace: localSelectedPlace
            ? formatGoogleAPIPlace({ place: localSelectedPlace })
            : null,
          searchMode: currentMode,
          traditionalSearchString: localTraditionalSearchString,
          priceRangeSetting: localPriceRangeInputValues,
          squareFootageSetting: localSquareFootageInputValues,
          beds: localBedroomInputValues,
          baths: localBathroomInputValues,
          selectedArchitecturalStyles: localArchitecturalStyleInputValues,
          selectedPropertyPerks: localPropertyPerksInputValues,
        }),
      );

      if (!location.pathname.startsWith("/discover")) {
        navigate("/discover/cards");
      }
      setIsSearchingFromPanel(false);
    }
    if (properties.length > 1) {
      handleSearchPanelClose();
    }
  }, [newResults]);

  useEffect(() => {
    if (!lastSearchValues) return;

    setLocalTraditionalSearchString(lastSearchValues.traditionalSearchString);
    setLocalPriceRangeInputValues(lastSearchValues.priceRangeSetting);
    setLocalSquareFootageInputValues(lastSearchValues.squareFootageSetting);
    setLocalBedroomInputValues(lastSearchValues.beds);
    setLocalBathroomInputValues(lastSearchValues.baths);
    setLocalArchitecturalStyleInputValues(
      lastSearchValues.selectedArchitecturalStyles,
    );
    setLocalPropertyPerksInputValues(lastSearchValues.selectedPropertyPerks);
  }, [lastSearchValues]);

  useEffect(() => {
    if (currentDisplayProperty) {
      handleSearchPanelClose();
      setSearchParams({ showing: currentDisplayProperty.id });
    }
  }, [currentDisplayProperty]);

  useEffect(() => {
    if (localSelectedPlace) {
      setLocalTraditionalSearchString(localSelectedPlace.formatted_address),
        handleSearchClick();
    }
  }, [localSelectedPlace]);

  const handleSearchClick = async () => {
    const searchData = formatGoogleAPIPlace({ place: localSelectedPlace });

    //add in price range, square footage, bedrooms, bathrooms, architectural styles, and property perks
    searchData.price_min = String(
      priceRangeValues[localPriceRangeInputValues[0]],
    );
    searchData.price_max = String(
      priceRangeValues[localPriceRangeInputValues[1]],
    );
    searchData.area_min = String(
      squareFeetValues[localSquareFootageInputValues[0]],
    );
    searchData.area_max = String(
      squareFeetValues[localSquareFootageInputValues[1]],
    );
    searchData.beds = String(
      bedroomsValues[localBedroomInputValues[0]],
    ).replace(/\+$/, "");
    searchData.baths = String(
      bathroomValues[localBathroomInputValues[0]],
    ).replace(/\+$/, "");
    searchData.style = localArchitecturalStyleInputValues;

    //for each selectedPropertyPerks add to searchData[key] = true
    localPropertyPerksInputValues.forEach((perk) => {
      searchData[perk] = true;
    });

    setIsSearchingFromPanel(true);
    dispatch(searchProperties(searchData));
  };

  const handleAiSearchClick = async () => {
    setIsSearchingFromPanel(true);
    dispatch(searchProperties({ question: aiSearchString }));
  };

  return (
    <>
      <motion.div
        initial="close"
        variants={overlayVariants}
        animate={controls}
        onClick={() => handleSearchPanelClose()}
        className={`${isShowingSearchPanel ? "" : "pointer-events-none"} fixed top-0 z-20 h-dvh w-dvw overflow-y-auto bg-black/30`}
      ></motion.div>
      <motion.div
        initial="close"
        variants={containerVariants}
        animate={controls}
        className={`${isShowingSearchPanel ? "" : "pointer-events-none"} fixed right-0 top-0 z-30 h-dvh w-dvw translate-x-full overflow-y-auto bg-white sm:max-w-sm`}
      >
        <header className="sticky top-0 z-10 flex flex-row items-center border-b border-b-gray-300 bg-white pl-3 pt-2">
          <button
            className="shrink-0 rounded-full bg-transparent p-2 duration-300 sm:hidden sm:hover:bg-gray-200"
            onClick={() => handleSearchPanelClose()}
          >
            <MdArrowBack className="h-6 w-6" />
          </button>
          <div className="relative ml-auto flex w-full flex-row items-center font-semibold text-gray-600">
            <button
              className={`flex flex-1 flex-row items-center justify-center gap-2 border-b-4 border-b-transparent p-3 ${currentMode === "ai" ? "pointer-events-none opacity-100" : "pointer-events-auto opacity-50"} transition-colors`}
              onClick={() => handleModeSwitch("ai")}
            >
              <SparkleIcon className="h-3 w-3 fill-gray-600" />
              AI Search
            </button>
            <button
              className={`flex flex-1 flex-row items-center justify-center gap-2 border-b-4 border-b-transparent p-3 ${currentMode === "traditional" ? "pointer-events-none opacity-100" : "pointer-events-auto opacity-50"} transition-colors`}
              onClick={() => handleModeSwitch("traditional")}
            >
              <MdSearch className="fill-gray-600" />
              Traditional
            </button>
            <div
              className={`absolute bottom-0 h-1 w-1/2 rounded-t-md bg-[#8E9991] ${currentMode === "ai" ? "left-0" : "left-1/2"} transition-all`}
            ></div>
          </div>
        </header>
        <div className="p-6">
          {currentMode === "ai" && (
            <div className="flex w-full max-w-xl flex-col justify-self-center">
              <label className="mt-2 w-full text-center font-bold text-gray-600">
                Describe the home you&apos;re looking for
              </label>
              <div className="mt-4 rounded-md ring-0 ring-transparent transition-shadow focus-within:ring-2 focus-within:ring-[#8E9991]/40 focus-within:ring-offset-2">
                <div className="relative inline-flex h-fit w-full overflow-hidden rounded-md">
                  <textarea
                    placeholder="Show me houses in Upper Arlington, Ohio near Tremont Elementary School with a max price of $500,000"
                    className="peer inline-block w-full resize-none rounded-md border border-[#8E9991] p-3 font-content outline-0 transition-all focus:border-[#8E9991]/50"
                    value={aiSearchString}
                    rows="6"
                    onKeyDown={(e) => {
                      // Check if the Enter key is pressed
                      if (e.key === "Enter") {
                        // Prevent default line break behavior
                        e.preventDefault();
                        if (e.target.value.trim().length === 0) return;
                        handleAiSearchClick();
                      }
                    }}
                    onChange={(e) => {
                      dispatch(setIsAiSearchingFailure(false));
                      dispatch(setAiSearchString(e.target.value));
                    }}
                  ></textarea>
                  <button
                    onClick={() => handleAiSearchClick()}
                    tabIndex={aiSearchString.trim().length === 0 ? "-1" : "0"}
                    className={` ${aiSearchString.trim().length === 0 ? "translate-y-full" : "translate-y-0"} absolute bottom-0 flex h-12 w-full flex-row items-center justify-center gap-2 bg-primary-500 text-white transition-transform`}
                  >
                    <span>Find my next home</span>{" "}
                    <MdArrowForward className="h-5 w-5 fill-white" />
                  </button>
                </div>
              </div>
              {isAiSearchingFailure && (
                <span className="block w-full p-1.5 px-1 text-sm text-red-500">
                  Sorry, your search returned no results. Please try again.
                </span>
              )}
            </div>
          )}
          {currentMode === "traditional" && (
            // put this back in for tradition text search   ${traditionalSearchString.trim().length > 0 ? "pr-32" : "pr-16"}
            <>
              <div className="flex flex-col gap-14">
                <div>
                  <div className="rounded-md ring-0 ring-transparent transition-shadow focus-within:ring-2 focus-within:ring-[#8E9991]/40 focus-within:ring-offset-2">
                    <div className="relative w-full overflow-hidden rounded-md">
                      <PlaceAutocomplete
                        textValue={localTraditionalSearchString}
                        className={`inline-block w-full rounded-md border border-[#8E9991] p-3 ${localSelectedPlace && isAnySelectorChanged ? "pr-28" : "pr-12"} font-content outline-0 transition-all focus:border-[#8E9991]/50`}
                        onPlaceSelect={onPlaceSelected}
                        onChange={(e) => onSearchQueryChange(e)}
                        ref={inputRef}
                      />
                      {localTraditionalSearchString && (
                        <button
                          data-close
                          onClick={() => {
                            //dispatch(setTraditionalSearchString(""));
                            setLocalTraditionalSearchString("");
                            dispatch(setIsTraditionalSearchingFailure(false));
                            if (inputRef.current) {
                              inputRef.current.disableAutocomplete(); // Clear the autocomplete suggestions and disable autocomplete
                              setTimeout(() => {
                                inputRef.current.enableAutocomplete(); // Re-enable autocomplete after hiding the dropdown
                                inputRef.current.focusInput(); // Focus the input
                              }, 150); // Adjust delay if necessary
                            }
                          }}
                          className={`absolute ${localSelectedPlace && isAnySelectorChanged ? "right-16" : "right-0"} top-0 flex h-12 w-12 items-center justify-center`}
                        >
                          <IoMdCloseCircle className="h-6 w-6 fill-neutral-300" />
                        </button>
                      )}

                      {localSelectedPlace && isAnySelectorChanged && (
                        <div
                          className={`absolute right-0 top-0 flex h-full flex-row ${
                            localTraditionalSearchString?.trim().length > 0
                              ? "translate-x-0"
                              : "translate-x-full"
                          } transition-transform`}
                        >
                          <button
                            onClick={() => handleSearchClick()}
                            tabIndex={
                              localTraditionalSearchString?.trim().length === 0
                                ? "-1"
                                : "0"
                            }
                            className="flex h-full w-16 flex-row items-center justify-center bg-primary-500"
                          >
                            <MdArrowForward className="m-4 h-6 w-6 fill-white" />
                          </button>
                        </div>
                      )}
                    </div>
                  </div>
                  {isTraditionalSearchingFailure && (
                    <span className="block w-full p-1.5 px-1 text-sm text-red-500">
                      Sorry, your search returned no results. Please try again.
                    </span>
                  )}
                </div>

                <RangeSelector
                  label="Price Range"
                  sliderValues={localPriceRangeInputValues}
                  format="$"
                  min={price_range.min}
                  max={price_range.max}
                  steps={priceRangeValues}
                  onChange={handlePriceRangeChange}
                  // initialValueMin={defaultPriceRangeSetting[0]}
                  // initialValueMax={defaultPriceRangeSetting[1]}
                />
                <RangeSelector
                  label="Square Feet"
                  sliderValues={localSquareFootageInputValues}
                  format=""
                  min={square_feet.min}
                  max={square_feet.max}
                  steps={squareFeetValues}
                  onChange={handleSquareFeetChange}
                  // initialValueMin={defaultSquareFootageSetting[0]}
                  // initialValueMax={defaultSquareFootageSetting[1]}
                />
                <MultiSelectRadioGroup
                  label="Bedrooms"
                  items={bedroomsValues}
                  values={localBedroomInputValues}
                  allowMultiple={false}
                  onChange={handleBedroomChange}
                  //note={"Minimum"}
                />

                <MultiSelectRadioGroup
                  label="Bathrooms"
                  items={bathroomValues}
                  values={localBathroomInputValues}
                  allowMultiple={false}
                  onChange={handleBathroomChange}
                  //note={"Minimum"}
                />
                <TextValueDisplay
                  label="Architectural Styles"
                  value={
                    currentArchitecturalStyles.length > 0
                      ? currentArchitecturalStyles
                      : "Any"
                  }
                  onClick={() =>
                    handleSearchOptionClick("architectural_styles")
                  }
                />
                <TagsValueDisplay
                  value={currentPropertyPerks}
                  label="Perks"
                  onClick={() => handleSearchOptionClick("perks")}
                />
              </div>
            </>
          )}
        </div>
        <AnimatePresence>
          {isSearching && (
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              className="absolute left-0 top-0 h-full w-full bg-white/90"
            >
              <div className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2">
                <SearchSpinner />
              </div>
            </motion.div>
          )}
        </AnimatePresence>
      </motion.div>
      <SearchOptionPanel
        label={
          searchOptionType
            ? searchOptions.find(({ id }) => id === searchOptionType).label
            : ""
        }
      >
        {searchOptionType === "architectural_styles" && (
          <SearchArchitecturalStyles
            prefix="search"
            values={localArchitecturalStyleInputValues}
            setValues={setLocalArchitecturalStyleInputValues}
            //onChange={(newStyles) => handleArchitecturalStyleChange(newStyles)}
          />
        )}

        {searchOptionType === "perks" && (
          <div className="p-10">
            <SelectPerks
              prefix="search"
              values={localPropertyPerksInputValues}
              setValues={setLocalPropertyPerksInputValues}
            />
          </div>
        )}
      </SearchOptionPanel>
    </>
  );
}

export default SearchPanel;

export const PlaceAutocomplete = forwardRef(
  (
    {
      onPlaceSelect,
      className,
      onChange,
      textValue,
      placeholder,
      specificAddress,
    },
    ref,
  ) => {
    const [placeAutocomplete, setPlaceAutocomplete] = useState(null);
    const inputRef = useRef(null);
    const places = useMapsLibrary("places");
    const [isAutocompleteEnabled, setIsAutocompleteEnabled] = useState(true);

    useImperativeHandle(ref, () => ({
      disableAutocomplete() {
        setIsAutocompleteEnabled(false);
        if (placeAutocomplete) {
          placeAutocomplete.setOptions({ types: [] }); // Disable autocomplete
        }
        inputRef.current.value = ""; // Clear the input value
        const event = new Event("input", { bubbles: true }); // Simulate an input event
        inputRef.current.dispatchEvent(event); // Trigger the input change event
      },
      enableAutocomplete() {
        setIsAutocompleteEnabled(true);
        if (placeAutocomplete) {
          placeAutocomplete.setOptions({ types: ["(regions)"] }); // Re-enable autocomplete
        }
      },
      focusInput() {
        inputRef.current?.focus(); // Focus the input
      },
    }));

    useEffect(() => {
      if (!places || !inputRef.current) return;

      const autocomplete = new places.Autocomplete(inputRef.current, {
        fields: [
          "type",
          "geometry",
          "name",
          "formatted_address",
          "address_components",
        ],
        componentRestrictions: { country: "us" },
        types: [
          "administrative_area_level_1",
          "locality",
          "postal_code",
          "premise",
          "subpremise",
        ], // Default types
      });

      setPlaceAutocomplete(autocomplete);
      return () => {
        // Cleanup the listener when component unmounts
        if (autocomplete) {
          window.google.maps.event.clearInstanceListeners(autocomplete);
        }
      };
    }, [places]);

    useEffect(() => {
      if (!placeAutocomplete) return;

      placeAutocomplete.addListener("place_changed", () => {
        onPlaceSelect(placeAutocomplete.getPlace());
      });
    }, [onPlaceSelect, placeAutocomplete]);

    const handleInputChange = (e) => {
      const inputValue = e.target.value;
      onChange(e);

      if (placeAutocomplete) {
        const looksLikeAddress = /^\d+[a-zA-Z\d\-]* /.test(inputValue);
        placeAutocomplete.setOptions({
          types:
            looksLikeAddress || specificAddress
              ? ["address"]
              : [
                  "administrative_area_level_1",
                  "locality",
                  "postal_code",
                  "premise",
                  "subpremise",
                ],
        });
      }
    };

    return (
      <input
        ref={inputRef} // Use the forwarded ref if available, or fallback to local ref
        value={textValue}
        onChange={handleInputChange}
        className={className}
        placeholder={placeholder ?? "Address, city, state, or zip"}
      />
    );
  },
);

// Adding the display name to the forwardRef component
PlaceAutocomplete.displayName = "PlaceAutocomplete";
