import React, { useEffect, useRef, useState } from "react";
import * as Dialog from "@radix-ui/react-dialog";
import BorderFlourish from "@src/assets/svgs/BorderFlourish.jsx";
import UnlistedLogo from "@src/assets/svgs/UnlistedLogo.jsx";
import Slide1 from "@src/assets/slide_1.jpg";
import { useSelector, useDispatch } from "react-redux";
import { MdArrowBack, MdArrowForward, MdClose, MdSearch } from "react-icons/md";
import { setOnboardingSlideshowComplete } from "@src/store/features/user/slice.js";
import { VisuallyHidden } from "@radix-ui/react-visually-hidden";
import { useNavigate } from "react-router-dom";

function IntroSlides() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const scrollContainerRef = useRef(null);
  const [activeSlide, setActiveSlide] = useState(0);
  const { onboardingSlideshowComplete } = useSelector((state) => state.user);
  const [open, setOpen] = useState(onboardingSlideshowComplete);

  const slides = [
    {
      title: "Welcome to Unlisted",
      content: (
        <>
          <h2 className="mb-4 flex flex-col gap-3 font-attention text-2xl text-neutral-800 sm:mb-4">
            Welcome to{" "}
            <UnlistedLogo className="inline-block h-[22px] w-auto text-gold-500" />
          </h2>
          <img
            src={Slide1}
            alt="Illustration of a modern home."
            className="mt-3 h-auto w-full max-w-[240px]"
          />
          <div className="max-w-sm">
            <p className="mt-2 text-balance font-content">
              Only 5% of homes are listed, but{" "}
              <span className="font-semibold tracking-tight md:tracking-normal">
                66% of homeowners are open to selling.
              </span>
            </p>
            <p className="mt-4 text-balance font-content">
              Unlisted lets you{" "}
              <span className="font-semibold tracking-tight md:tracking-normal">
                discover hidden gems in the market
              </span>{" "}
              that others can’t see.
            </p>
          </div>
        </>
      ),
    },
    {
      title: "Explore the REAL real estate market",
      content: (
        <>
          <h2 className="mb-4 font-attention text-3xl text-neutral-800">
            Explore the{" "}
            <span className="block text-4xl font-light italic text-gold-500">
              REAL
            </span>{" "}
            real estate market
          </h2>
          <div className="max-w-sm">
            <p className="mt-4 text-balance font-content">
              Find off-market homes, save favorites, and{" "}
              <span className="font-semibold tracking-tight md:tracking-normal">
                contact homeowners
              </span>{" "}
              to learn if and when they&apos;d sell.
            </p>
          </div>
        </>
      ),
    },
    {
      title: "Your home. Your story.",
      content: (
        <>
          <h2 className="mb-4 font-attention text-4xl text-neutral-800">
            Your home.
            <span className="block text-4xl font-light text-gold-500">
              Your story.
            </span>
          </h2>
          <div className="max-w-sm">
            <p className="mt-4 text-balance font-content">
              <span className="font-semibold tracking-tight md:tracking-normal">
                Claim your home
              </span>{" "}
              and make sure the details are accurate, up-to-date, and uniquely
              yours.
            </p>
            <button
              onClick={() => {
                setOpen(false);
                navigate("/claim");
                setTimeout(() => {
                  dispatch(setOnboardingSlideshowComplete(true));
                }, 400);
              }}
              className="mx-auto mt-12 flex w-fit justify-center gap-2 rounded-md bg-[#bdab79] p-2.5 px-6 font-semibold text-white shadow-lg shadow-gold-500/10 transition-all hover:bg-[#b29c62]"
            >
              <span>Claim your home</span>
            </button>
          </div>
        </>
      ),
    },
  ];

  const handleScroll = (event) => {
    const scrollContainer = event.currentTarget;
    const { scrollLeft, clientWidth } = scrollContainer;
    const newIndex = Math.round(scrollLeft / clientWidth);
    setActiveSlide(newIndex);
  };

  const scrollToSlide = (index) => {
    const scrollContainer = scrollContainerRef.current;
    if (scrollContainer) {
      const slideWidth = scrollContainer.clientWidth;
      scrollContainer.scrollTo({
        left: slideWidth * index,
        behavior: "smooth",
      });
      setTimeout(() => {
        setActiveSlide(index);
      }, 400);
    }
  };

  const handleNextSlide = () => {
    if (activeSlide < slides.length - 1) {
      scrollToSlide(activeSlide + 1);
    }
  };

  const handlePrevSlide = () => {
    if (activeSlide > 0) {
      scrollToSlide(activeSlide - 1);
    }
  };

  useEffect(() => {
    setOpen(true);
  }, []);

  return (
    <>
      {!onboardingSlideshowComplete ? (
        <Dialog.Root open={open} onOpenChange={setOpen}>
          {/*<Dialog.Trigger asChild>*/}
          {/*  <button className="relative z-50">Open Dialog</button>*/}
          {/*</Dialog.Trigger>*/}
          <Dialog.Portal>
            <Dialog.Overlay className="fixed inset-0 z-50 bg-black/60 data-[state=closed]:animate-[fade-out_300ms] data-[state=open]:animate-[fade-in_300ms]" />
            <Dialog.Content
              onOpenAutoFocus={(e) => e.preventDefault()}
              className="fixed inset-0 z-50 flex flex-col data-[state=closed]:animate-[slight-slide-down-fade-out_300ms] data-[state=open]:animate-[slight-slide-up-fade-in_300ms]"
            >
              <VisuallyHidden>
                <Dialog.Title>Welcome to Unlisted</Dialog.Title>
                <Dialog.Description>
                  A walkthrough of the core features of this site.
                </Dialog.Description>
              </VisuallyHidden>
              <div className="absolute left-1/2 top-1/2 flex h-fit w-[min(calc(100%-2rem),40rem)] flex-1 -translate-x-1/2 -translate-y-1/2 flex-col rounded-md bg-white">
                <div className="relative m-6 flex h-fit flex-1 flex-col rounded-sm border border-gold-400">
                  <BorderFlourish className="absolute left-1/2 -translate-x-1/2 -translate-y-1/2 text-gold-400" />
                  <button
                    className="absolute right-3 top-3 flex items-center text-xs uppercase text-gold-500 transition-all hover:text-gold-600"
                    onClick={() => {
                      setOpen(false);
                      setTimeout(() => {
                        dispatch(setOnboardingSlideshowComplete(true));
                      }, 400);
                    }}
                  >
                    <span>Close</span>
                    <MdClose className="ml-1 inline-block h-4 w-4" />
                  </button>
                  <button
                    onClick={handlePrevSlide}
                    className={`absolute bottom-0 left-0 flex items-center justify-center rounded-full bg-white/80 p-3 text-gold-500/60 transition-all hover:text-gold-500 md:bottom-auto md:top-1/2 md:block md:-translate-y-1/2 ${activeSlide === 0 ? "hidden md:hidden" : ""}`}
                  >
                    <MdArrowBack className="h-6 w-6 text-current" />
                  </button>
                  <button
                    onClick={handleNextSlide}
                    className={`absolute bottom-0 right-0 flex items-center justify-center rounded-full bg-white/80 p-3 text-gold-500/60 transition-all hover:text-gold-500 md:bottom-auto md:top-1/2 md:block md:-translate-y-1/2 ${activeSlide === slides.length - 1 ? "hidden md:hidden" : ""}`}
                  >
                    <MdArrowForward className="h-6 w-6 fill-current" />
                  </button>

                  {/* Scrollable Container */}
                  <div
                    ref={scrollContainerRef}
                    onScrollCapture={handleScroll}
                    className="hide-scrollbar flex h-fit flex-1 snap-x snap-mandatory overflow-x-auto text-neutral-700"
                  >
                    {slides.map((slide, index) => (
                      <div
                        key={index}
                        className="flex h-fit h-full min-h-[65vh] w-full shrink-0 snap-start flex-col items-center justify-center p-6 pb-16 text-center font-attention"
                      >
                        {slide.content}
                      </div>
                    ))}
                  </div>

                  {/* Scroll Indicator Dots */}
                  <div className="absolute bottom-3 left-1/2 flex -translate-x-1/2 justify-center space-x-2">
                    {slides.map((_, index) => (
                      <span
                        key={index}
                        onClick={() => scrollToSlide(index)}
                        className={`h-2 w-2 cursor-pointer rounded-full ${index === activeSlide ? "bg-gold-500" : "bg-gray-300"}`}
                      ></span>
                    ))}
                  </div>
                </div>
              </div>
            </Dialog.Content>
          </Dialog.Portal>
        </Dialog.Root>
      ) : null}
    </>
  );
}

export default IntroSlides;
