import React from "react";
import SelectableTag from "@src/components/SelectableTag.jsx";

function PropertyProfileDescription({ property }) {
  if (!property.owner_blurb) return null;
  return (
    <div>
      <h2 className="mb-3 font-attention text-lg text-neutral-800">About</h2>
      <div className="flex flex-wrap gap-2 whitespace-pre-line text-sm leading-[160%] text-neutral-700">
        {property.owner_blurb}
      </div>
    </div>
  );
}

export default PropertyProfileDescription;
