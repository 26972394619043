import { useSearchParams } from "react-router-dom";
import PropertyProfileImage from "../../components/propertyProfile/propertyProfileImage/PropertyProfileImage.jsx";
import PropertyProfileFavoriteButton from "@src/components/propertyProfile/propertyProfileButtons/PropertyProfileFavoriteButton.jsx";
import { abbreviateNumber } from "@src/utils/misc.js";
import PropertyProfileHeadline from "@src/components/propertyProfile/propertyProfileHeadline/PropertyProfileHeadline.jsx";
import PropertyProfileSubheading from "@src/components/propertyProfile/propertyProfileSubheading/PropertyProfileSubheading.jsx";
import PropertyProfileDetails from "@src/components/propertyProfile/propertyProfileDetails/PropertyProfileDetails.jsx";

function ListItem({ property }) {
  const [searchParams, setSearchParams] = useSearchParams();
  const estimatedValue =
    property.owner_expected_value ||
    property.estimated_value ||
    property.estimated_value_one ||
    0;

  return (
    <div
      className="shrink-0 overflow-hidden rounded-xl border border-gray-200 shadow-md shadow-neutral-200"
      onClick={() => {
        setSearchParams({ showing: property.id });
      }}
    >
      <div className="relative aspect-video w-full">
        <PropertyProfileImage property={property} />
        <div className="absolute right-2 top-2">
          <PropertyProfileFavoriteButton
            propertyId={property?.id}
            isLiked={property?.liked}
          />
        </div>
      </div>

      <div className="flex flex-row items-center justify-between p-3 pt-2 capitalize">
        <div className="flex flex-col gap-0.5">
          <PropertyProfileHeadline
            property={property}
            className="font-attention text-xl font-light text-neutral-800"
          />
          <PropertyProfileSubheading
            property={property}
            className="text-xs text-neutral-700"
          />
          <div className="mt-1">
            <PropertyProfileDetails
              property={property}
              fontSize="text-xs"
              textColor="text-neutral-700"
              gapSize="gap-2"
            />
          </div>
        </div>
        {estimatedValue ? (
          <div
            data-price
            className="flex shrink-0 -translate-y-2 flex-col items-center"
          >
            <div className="font-attention text-xl text-[#1a1a1a]">
              <sup>$</sup>
              {abbreviateNumber(estimatedValue)}
            </div>
            <span className="text-[0.6rem] text-neutral-400">Estimated</span>
          </div>
        ) : null}
      </div>
    </div>
  );
}

export default ListItem;
