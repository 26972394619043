const property_perks = [
  { id: "balcony", text: "Balcony", emoji: "🏠" },
  { id: "breezeway", text: "Breezeway", emoji: "🌬️" },
  { id: "boat_access", text: "Boat Access", emoji: "🚤" },
  { id: "cabin", text: "Cabin", emoji: "🏡" },
  { id: "elevator", text: "Elevator", emoji: "🛗" },
  { id: "game_room", text: "Game Room", emoji: "🎮" },
  { id: "garage", text: "Garage", emoji: "🚗" },
  { id: "guest_house", text: "Guest House", emoji: "🏘️" },
  { id: "universal_access", text: "Universal Access", emoji: "♿" },
  { id: "office", text: "Office", emoji: "💼" },
  { id: "pond", text: "Pond", emoji: "🏞️" },
  { id: "sauna", text: "Sauna", emoji: "🧖" },
  { id: "tennis_court", text: "Tennis Court", emoji: "🎾" },
  { id: "wine_cellar", text: "Wine Cellar", emoji: "🍷" },
  { id: "pool", text: "Pool", emoji: "🏊" },
];

// const property_perks = [{id: 'attached_garage', text: "Attached Garage", emoji: "🚗"}, {
//     id: 'detached_garage',
//     text: "Detached Garage",
//     emoji: "🚙"
// }, {id: 'pool', text: "Pool", emoji: "🏊"}, {id: 'garden', text: "Garden", emoji: "🌻"}, {
//     id: 'fireplace',
//     text: "Fireplace",
//     emoji: "🔥"
// },
//     {id: 'basement', text: "Basement", emoji: "🏠"}, {id: 'attic', text: "Attic", emoji: "🏠"}, {
//     id: 'patio',
//     text: "Patio",
//     emoji: "🏠"
// }, {id: 'balcony', text: "Balcony", emoji: "🏠"}, {id: 'porch', text: "Porch", emoji: "🏠"}, {
//     id: 'deck',
//     text: "Deck",
//     emoji: "🏠"
// }, {id: 'fenced_yard', text: "Fenced Yard", emoji: "🏠"}, {
//     id: 'waterfront',
//     text: "Waterfront",
//     emoji: "🏠"
// }, {id: 'lake_view', text: "Lake View", emoji: "🏠"}, {
//     id: 'mountain_view',
//     text: "Mountain View",
//     emoji: "🏠"
// }, {id: 'city_view', text: "City View", emoji: "🏠"}, {
//     id: 'ocean_view',
//     text: "Ocean View",
//     emoji: "🏠"
// }, {id: 'golf_course', text: "Golf Course", emoji: "🏠"}, {
//     id: 'tennis_court',
//     text: "Tennis Court",
//     emoji: "🏠"
// }, {id: 'gym', text: "Gym", emoji: "🏠"}, {id: 'home_theater', text: "Home Theater", emoji: "🏠"}, {
//     id: 'game_room',
//     text: "Game Room",
//     emoji: "🏠"
// }, {id: 'guest_suite', text: "Guest Suite", emoji: "🏠"}, {id: 'in_law_suite', text: "In-Law Suite", emoji: "🏠"},];

export default property_perks;
