import React, { useEffect, useState, useCallback } from "react";
import { useInView } from "react-intersection-observer";
import { cacheImage } from "../../../store/features/imageCache/slice.js";
import { useSelector, useDispatch } from "react-redux";
import { useApiIsLoaded } from '@vis.gl/react-google-maps';

function PropertyProfileImage({ property }) {
  const dispatch = useDispatch();
  const { ref, inView } = useInView({
    triggerOnce: true,
    rootMargin: "2000px 2000px", // Adjust for preloading before element comes into view
  });

  // Get cached image URL from Redux
  const cachedImageUrl = useSelector((state) => state.imageCache[property.id]);

  // Internal state to manage image URL and loading state
  const [imageUrl, setImageUrl] = useState(cachedImageUrl || null);
  const [isFetching, setIsFetching] = useState(false); // Track if an image is being fetched

  // Use hook to check if API is loaded
  const isLoaded = useApiIsLoaded();

  // Preload image to prevent flickering
  const preloadImage = (url) => {
    const img = new Image();
    img.src = url;
    img.onload = () => {
      setImageUrl(url); // Set image URL once loaded
      dispatch(cacheImage({ propertyId: property.id, imageUrl: url })); // Cache the URL in Redux
      setIsFetching(false); // Fetching complete
    };
  };

  // Fetch image and check content length
  const fetchImageWithContentLength = async (url) => {
    try {
      const response = await fetch(url);
      const contentLength = response.headers.get("content-length");

      // Assume "no imagery" image is less than a certain size
      return contentLength && parseInt(contentLength, 10) >= 10000;
    } catch (error) {
      console.error("Error fetching image:", error);
      return false;
    }
  };

  // Compute heading between two locations
  const computeHeading = (originLatLng, propertyLatLng) => {
    return window.google.maps.geometry.spherical.computeHeading(
      originLatLng,
      propertyLatLng
    );
  };

  // Fetch directions and street view using DirectionsService
  const fetchDirectionsAndStreetView = useCallback(async () => {
    if (!isLoaded || !window.google) {
      console.warn('Google Maps API is not loaded yet.');
      return;
    }

    const directionsService = new window.google.maps.DirectionsService();
    const fullAddress = `${property.address_line_1} ${property.city} ${property.state} ${property.zip}`;

    const request = {
      origin: fullAddress,
      destination: fullAddress,
      travelMode: window.google.maps.TravelMode.DRIVING,
    };

    return new Promise((resolve, reject) => {
      directionsService.route(request, (result, status) => {
        if (
          status === window.google.maps.DirectionsStatus.OK &&
          result.routes.length > 0
        ) {
          const steps = result.routes[0].legs[0].steps;
          const streetLocation = steps[steps.length - 1].start_location;
          const propertyLocation = new window.google.maps.LatLng(
            property.latitude,
            property.longitude
          );

          const heading = computeHeading(streetLocation, propertyLocation);

          resolve({ streetLocation, heading });
        } else {
          console.error("Failed to fetch directions:", status);
          reject("Failed to fetch directions");
        }
      });
    });
  }, [isLoaded, property]);

  // Fetch street view URL
  const fetchStreetViewUrl = useCallback(async () => {
    if (isFetching) return; // Avoid multiple fetches
    setIsFetching(true);

    // If cover image is provided, cache it and stop further fetching
    if (property.cover_photo_url) {
      preloadImage(property.cover_photo_url); // Preload and cache the cover image
      return;
    }

    // If image is already cached, use it
    if (cachedImageUrl) {
      preloadImage(cachedImageUrl);
      return;
    }

    // Otherwise, fetch and cache the Google Street View image
    try {
      const { streetLocation, heading } = await fetchDirectionsAndStreetView();
      const streetViewUrl = `https://maps.googleapis.com/maps/api/streetview?size=640x640&location=${streetLocation.lat()},${streetLocation.lng()}&fov=80&heading=${heading}&pitch=0&scale=2&source=outdoor&key=${import.meta.env.VITE_GOOGLE_MAPS_API_KEY}`;

      const isValidImage = await fetchImageWithContentLength(streetViewUrl);
      if (isValidImage) {
        preloadImage(streetViewUrl);
      } else {
        console.warn("Invalid Street View image, switching to aerial view");
        displayAerialView();
      }
    } catch (error) {
      console.error("Error fetching Street View:", error);
      displayAerialView();
    }
  }, [isFetching, property.cover_photo_url, cachedImageUrl, fetchDirectionsAndStreetView]);

  // Fallback aerial view
  const displayAerialView = () => {
    const aerialViewUrl = `https://maps.googleapis.com/maps/api/staticmap?center=${property.latitude},${property.longitude}&zoom=20&size=640x640&maptype=satellite&key=${import.meta.env.VITE_GOOGLE_MAPS_API_KEY}`;
    preloadImage(aerialViewUrl);
    dispatch(cacheImage({ propertyId: property.id, imageUrl: aerialViewUrl }));
  };

  useEffect(() => {
    if (isLoaded && inView && !cachedImageUrl && !isFetching) {
      fetchStreetViewUrl();
    }
  }, [isLoaded, inView, cachedImageUrl, isFetching, fetchStreetViewUrl]);

  return (
    <>
      {inView ? (
        <>
          {property.cover_photo_url ? (
            <img
              className="h-full w-full object-cover"
              src={property.cover_photo_url}
              alt={`Cover photo of ${property.address_line_1}`}
            />
          ) : imageUrl ? (
            <img
              className="h-full w-full object-cover"
              src={imageUrl}
              alt={`Street view of ${property.address_line_1}`}
            />
          ) : (
            <div className="flex h-full w-full items-center justify-center bg-gray-200"></div>
          )}
        </>
      ) : (
        <>
          {cachedImageUrl ? (
            <img
              className="h-full w-full object-cover"
              src={cachedImageUrl}
              alt={`Street view of ${property.address_line_1}`}
            />
          ) : (
            <div
              ref={ref}
              className="flex h-full w-full items-center justify-center bg-gray-200"
            ></div>
          )}
        </>
      )}
    </>
  );
}

export default PropertyProfileImage;
