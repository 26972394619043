import home_details from "@src/data/home_details.js";
import architectural_styles from "@src/data/architectural_styles.js";
import { formatNumber } from "@src/utils/misc.js";
import ArchitecturalStyleIcon from "@src/assets/svgs/ArchitecturalStyleIcon.jsx";

function PropertyProfileDetailsGrid({
  property,
  fontSize,
  textColor,
  gapSize,
}) {
  const foundDetails = home_details
    .filter(
      (prop) => property[prop.id] !== null || property[prop.owner_id] !== null,
    )
    //then map over the filtered details and return an object with the icon and value
    .map((prop) => {
      //we know of the two is not null, so we can return the one that is not null, giving preference to the owner_id
      const value = property[prop.owner_id] || property[prop.id];
      //return the object with the icon and value
      return {
        icon: prop.icon,
        id: prop.id,
        value: value,
        label: value > 1 ? prop.labelPlural : prop.labelSingular,
      };
      // }
    });

  const foundStyle = architectural_styles.find(
    ({ id }, arr) =>
      id !== 12 && (id === property.owner_given_style || id === property.style),
  );

  const styleDetail = foundStyle && {
    // icon: foundStyle.icon,
    icon: ArchitecturalStyleIcon,
    label: foundStyle.label,
  };

  const bedrooms = foundDetails.find(({ id }) => id === "bedrooms");
  const bathrooms = foundDetails.find(({ id }) => id === "bathrooms");
  const building_area = foundDetails.find(({ id }) => id === "building_area");

  if (!bedrooms && !bathrooms && !building_area && !styleDetail) return null;

  return (
    <div
      className={`grid auto-cols-fr grid-flow-col overflow-hidden rounded-lg bg-transparent shadow-md shadow-neutral-300/10 shadow-transparent ring-1 ring-primary-500/40 md:bg-primary-500/40 ${gapSize ? gapSize : "gap-[0px]"} items-start font-content opacity-90 ${fontSize ? fontSize : "text-sm"} ${textColor ? textColor : "text-white"}`}
    >
      {styleDetail && (
        <Detail
          value={null}
          label={styleDetail.label}
          icon={styleDetail.icon}
        />
      )}
      {bedrooms?.value > 0 && (
        <Detail
          value={bedrooms.value}
          label={bedrooms.label}
          icon={bedrooms.icon}
        />
      )}
      {bathrooms?.value > 0 && (
        <Detail
          value={bathrooms.value}
          label={bathrooms.label}
          icon={bathrooms.icon}
        />
      )}
      {building_area?.value > 0 && (
        <Detail
          value={formatNumber(building_area.value)}
          label={building_area.label}
          icon={building_area.icon}
        />
      )}
    </div>
  );
}

const Detail = ({ value, label = "", icon: Icon }) => {
  return (
    <div className="flex flex-col items-center justify-center gap-3 bg-white p-4 md:flex-row">
      {Icon && (
        <Icon className="h-8 w-8 shrink-0 fill-current opacity-50 md:h-6 md:w-6 md:opacity-80" />
      )}
      <p className="flex gap-1 truncate whitespace-nowrap text-wrap text-center text-xs sm:text-sm">
        {value ? <span className="text tracking-tighter">{value} </span> : null}
        <span className="">{label}</span>
      </p>
    </div>
  );
};

export default PropertyProfileDetailsGrid;
