import {
  getHomeownerProperties,
  updateLoggedInUserInfo,
  verifyUserOTP,
} from "@src/api/user";
import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  fetchLikedProperties,
  likeProperty,
  unlikeProperty,
} from "@src/api/property";
import { setRandomAndSearchPropertyLikedStatus } from "@src/store/features/search/slice";
import { changeStatusInLikedHome } from "./slice";
import { setPropertyLikedStatus } from "@src/store/features/property/slice";

export const verifyUser = createAsyncThunk(
  "user/verify_user",
  async (data, { rejectWithValue }) => {
    try {
      const response = await verifyUserOTP(data);

      return response;
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const getUsersClaimedHomes = createAsyncThunk(
  "user/claimed_homes",
  async (data, { rejectWithValue }) => {
    try {
      const response = await getHomeownerProperties(data);

      return response;
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const getUsersLikedHomes = createAsyncThunk(
  "user/liked_homes",
  async (params, { rejectWithValue }) => {
    try {
      const response = await fetchLikedProperties(params);

      const updatedProperties = response?.properties?.map((item) => {
        return {
          ...item,
          liked: true,
        };
      });

      return {
        ...response,
        properties: updatedProperties,
      };
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const likeHome = createAsyncThunk(
  "user/like_home",
  async (propertyId, { dispatch, rejectWithValue }) => {
    try {
      const response = await likeProperty(propertyId);

      dispatch(
        setRandomAndSearchPropertyLikedStatus({ propertyId, liked: true }),
      );
      dispatch(changeStatusInLikedHome({ propertyId, liked: true }));
      dispatch(setPropertyLikedStatus({ propertyId, liked: true }));

      return response;
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const unlikeHome = createAsyncThunk(
  "user/unlike_home",
  async (propertyId, { dispatch, rejectWithValue }) => {
    try {
      const response = await unlikeProperty(propertyId);

      dispatch(
        setRandomAndSearchPropertyLikedStatus({ propertyId, liked: false }),
      );
      dispatch(changeStatusInLikedHome({ propertyId, liked: false }));
      dispatch(setPropertyLikedStatus({ propertyId, liked: false }));

      return response;
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const updateUserDetails = createAsyncThunk(
  "user/update_user_details",
  async (data, { rejectWithValue }) => {
    try {
      const response = await updateLoggedInUserInfo(data);

      return response;
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);
