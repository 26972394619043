import React, { useEffect } from "react";
import { closeSearchOptionPanel } from "@src/store/features/search/slice.js";
import { MdArrowBack } from "react-icons/md";
import { useSearchParams } from "react-router-dom";
import { motion } from "framer-motion";

function PropertyProfileEditableItemPanel({ editableItem, onExit }) {
  const [searchParams, setSearchParams] = useSearchParams();

  const handleClose = () => {
    const newSearchParams = new URLSearchParams(searchParams);
    newSearchParams.delete("item");
    setSearchParams(newSearchParams);
    onExit();
  };

  return (
    <motion.div
      initial={{ x: "100%" }}
      animate={{ x: 0 }}
      transition={{ type: "tween", duration: 0.15, ease: "easeInOut" }}
      exit={{ x: "100%" }}
      className="fixed right-0 top-0 z-40 flex h-dvh w-full flex-col items-center overflow-y-hidden bg-[#fafafa]"
    >
      <header className="sticky left-0 top-0 z-10 flex w-full flex-row items-center justify-between py-3 pl-3">
        <button
          className="shrink-0 rounded-full p-2 duration-300 md:hover:bg-gray-200"
          onClick={handleClose}
        >
          <MdArrowBack className="h-6 w-6" />
        </button>
      </header>
      {editableItem.inputComponent}
    </motion.div>
  );
}

export default PropertyProfileEditableItemPanel;
