import { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { getProposalData } from "@src/api/chat";
import { USER_ID, PROPERTY_TO_SAVE } from "@src/utils/constants";
import OverlayLoader from "@src/components/overlayLoader/OverlayLoader";
import { useDispatch } from "react-redux";
import { setProposalDetails } from "@src/store/features/proposal/slice";
import SignInModal from "@src/components/signInModal/SignInModal";

const ChatViewAccess = ({ children }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { proposal_id } = useParams();
  const userID = JSON.parse(localStorage.getItem(USER_ID));
  const propertyId = JSON.parse(localStorage.getItem(PROPERTY_TO_SAVE));
  const [isLoading, setIsLoading] = useState(true);
  const [hasAccess, setHasAccess] = useState(false);

  useEffect(() => {
    const checkAccess = async () => {
      try {
        const response = userID ? await getProposalData(proposal_id) : {};
        if (
          response?.proposal_data?.buyer_id === userID ||
          response?.proposal_data?.owner_id === userID ||
          !!propertyId
        ) {
          await dispatch(
            setProposalDetails({ proposalId: proposal_id, data: response }),
          );
          setHasAccess(true);
        } else {
          toast.error(
            "Sorry, you don't have access to this chat. Please make sure you are logged in using the correct email.",
          );
          // navigate("/");
        }
      } catch (error) {
        if (!propertyId) {
          toast.error("Sorry, we couldn't get that chat");
          // navigate("/");
        }
      }
      setIsLoading(false);
    };
    checkAccess();
  }, [proposal_id, userID]);

  if (isLoading) {
    return <OverlayLoader />;
  }

  if (hasAccess) {
    return children;
  }
  navigate("/");
  return null;
};

export default ChatViewAccess;
