import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { searchProperties, fetchRandomProperties } from "./service";

const initialState = {
  properties: [],
  randomProperties: [],
  isSearching: false,
  isFilterSearching: false,
  selectedPlace: null,
  isFetchingRandomProperties: false,
  isShowingSearchPanel: false,
  isShowingSearchOptionPanel: false,
  isTraditionalSearchingSuccess: false,
  isTraditionalSearchingFailure: false,
  isAiSearchingFailure: false,
  currentMode: "traditional",
  searchOptionType: null,
  selectedArchitecturalStyles: [],
  selectedPropertyPerks: [],
  //bedrooms: ["Any"],
  // bathrooms: ["Any"],
  // priceRange: [23, 64],
  //squareFootage: [400, 800],
  traditionalSearchString: "",
  aiSearchString: "",
  selectedPlaceId: null,
  newResults: 0,
  lastSearchValues: null,
};

export const slice = createSlice({
  name: "search",
  initialState,
  reducers: {
    setIsFilterSearching: (state, action) => {
      state.isFilterSearching = action.payload;
    },
    setIsSearching: (state, action) => {
      state.isSearching = action.payload;
    },

    setIsTraditionalSearchingSuccess: (state, action) => {
      state.setIsTraditionalSearchingSuccess = action.payload;
    },

    setIsTraditionalSearchingFailure: (state, action) => {
      state.isTraditionalSearchingFailure = action.payload;
    },

    setIsAiSearchingFailure: (state, action) => {
      state.isAiSearchingFailure = action.payload;
    },

    setIsFetchingRandomProperties: (state, action) => {
      state.isFetchingRandomProperties = action.payload;
    },
    openSearchPanel: (state) => {
      state.isShowingSearchPanel = true;
    },

    closeSearchPanel: (state) => {
      state.isShowingSearchPanel = false;
    },

    setSearchOptionType: (state, action) => {
      state.searchOptionType = action.payload;
    },

    openSearchOptionPanel: (state) => {
      state.isShowingSearchOptionPanel = true;
    },

    closeSearchOptionPanel: (state) => {
      state.isShowingSearchOptionPanel = false;
    },

    setMode: (state, action) => {
      state.currentMode = action.payload;
    },

    setProperties: (state, action) => {
      state.properties = action.payload;
    },

    setArchitecturalStyles: (state, action) => {
      state.selectedArchitecturalStyles = action.payload;
    },

    toggleArchitecturalStyle: (state, action) => {
      if (state.selectedArchitecturalStyles.includes(action.payload)) {
        state.selectedArchitecturalStyles =
          state.selectedArchitecturalStyles.filter(
            (style) => style !== action.payload,
          );
        return;
      }
      state.selectedArchitecturalStyles = [
        ...state.selectedArchitecturalStyles,
        action.payload,
      ];
    },

    setPropertyPerks: (state, action) => {
      state.selectedPropertyPerks = action.payload;
    },

    togglePropertyPerk: (state, action) => {
      if (state.selectedPropertyPerks.includes(action.payload)) {
        state.selectedPropertyPerks = state.selectedPropertyPerks.filter(
          (perk) => perk !== action.payload,
        );
        return;
      }
      state.selectedPropertyPerks = [
        ...state.selectedPropertyPerks,
        action.payload,
      ];
    },

    setBedrooms: (state, action) => {
      state.bedrooms = action.payload;
    },

    setBathrooms: (state, action) => {
      state.bathrooms = action.payload;
    },

    setPriceRange: (state, action) => {
      state.priceRange = action.payload;
    },

    setSquareFootage: (state, action) => {
      state.squareFootage = action.payload;
    },

    setTraditionalSearchString: (state, action) => {
      state.traditionalSearchString = action.payload;
    },

    setAiSearchString: (state, action) => {
      state.aiSearchString = action.payload;
    },

    setPropertyAsSwipedTo: (state, action) => {
      const property = state.properties.find(
        (property) => property.id === action.payload.id,
      );
      if (property) property.swipedTo = action.payload.swipedTo;

      const randomProperty = state.randomProperties.find(
        (property) => property.id === action.payload.id,
      );
      if (randomProperty) randomProperty.swipedTo = action.payload.swipedTo;
    },

    setSelectedPlaceId: (state, action) => {
      state.selectedPlaceId = action.payload;
    },
    setRandomAndSearchPropertyLikedStatus: (state, { payload }) => {
      const randomPropertyIndex = state.randomProperties.findIndex(
        (item) => item?.id === payload.propertyId,
      );
      if (state.randomProperties[randomPropertyIndex]) {
        state.randomProperties[randomPropertyIndex] = {
          ...state.randomProperties[randomPropertyIndex],
          liked: payload.liked,
        };
      }

      const searchPropertyIndex = state.properties.findIndex(
        (item) => item?.id === payload.propertyId,
      );
      if (state.properties[searchPropertyIndex]) {
        state.properties[searchPropertyIndex] = {
          ...state.properties[searchPropertyIndex],
          liked: payload.liked,
        };
      }
    },
    setAllRandomAndSearchPropertyLikedStatusAndOwner: (state) => {
      const updatedRandomProperties = state.randomProperties.map((item) => ({
        ...item,
        liked: false,
        owner_id: null,
      }));

      state.randomProperties = updatedRandomProperties;

      const updatedSearchProperties = state.properties.map((item) => ({
        ...item,
        liked: false,
        owner_id: null,
      }));

      state.properties = updatedSearchProperties;
    },
    setRandomAndSearchPropertyOwner: (state, { payload }) => {
      const randomPropertyIndex = state.randomProperties.findIndex(
        (item) => item?.id === payload.propertyId,
      );
      if (state.randomProperties[randomPropertyIndex]) {
        state.randomProperties[randomPropertyIndex] = {
          ...state.randomProperties[randomPropertyIndex],
          owner_id: payload.ownerId,
        };
      }

      const searchPropertyIndex = state.properties.findIndex(
        (item) => item?.id === payload.propertyId,
      );
      if (state.properties[searchPropertyIndex]) {
        state.properties[searchPropertyIndex] = {
          ...state.properties[searchPropertyIndex],
          owner_id: payload.ownerId,
        };
      }
    },

    setLastSearchValues: (state, action) => {
      state.lastSearchValues = action.payload;
    },
  },

  extraReducers: (builder) => {
    builder
      //searchProperties
      .addCase(searchProperties.pending, (state) => {
        state.isSearching = true;
      })
      .addCase(searchProperties.fulfilled, (state, action) => {
        console.log("fullfilled", action.payload);
        state.isSearching = false;
        if (action.payload?.properties?.length === 0) {
          if (state.currentMode === "traditional") {
            state.isTraditionalSearchingFailure = true;
            state.isFilterSearching = false;
          }
          if (state.currentMode === "ai") state.isAiSearchingFailure = true;
        }

        if (action.payload?.properties?.length > 1) {
          state.properties = action.payload.properties;
          state.newResults += 1;
        } else if (action.payload?.properties?.length === 1) {
          // state.properties = state.properties.concat(action.payload.properties);
          state.currentDisplayProperty = action.payload.properties[0];
        }
      })
      .addCase(searchProperties.rejected, (state, action) => {
        state.isSearching = false;
        if (state.currentMode === "traditional") {
          state.isTraditionalSearchingFailure = true;
          state.isFilterSearching = false;
        }

        if (state.currentMode === "ai") state.isAiSearchingFailure = true;
        state.error = action.payload;
      })

      //fetchRandomProperties
      .addCase(fetchRandomProperties.pending, (state) => {
        state.isFetchingRandomProperties = true;
      })
      .addCase(fetchRandomProperties.fulfilled, (state, action) => {
        state.isFetchingRandomProperties = false;
        state.randomProperties = action.payload.properties;
      })
      .addCase(fetchRandomProperties.rejected, (state, action) => {
        state.isFetchingRandomProperties = false;
        state.error = action.payload;
      });
  },
});

export const {
  setIsSearching,
  setIsFetchingRandomProperties,
  openSearchPanel,
  closeSearchPanel,
  setMode,
  setProperties,
  setSearchOptionType,
  openSearchOptionPanel,
  closeSearchOptionPanel,
  setArchitecturalStyles,
  setPropertyPerks,
  togglePropertyPerk,
  toggleArchitecturalStyle,
  setBedrooms,
  setBathrooms,
  setPriceRange,
  setSquareFootage,
  setTraditionalSearchString,
  setAiSearchString,
  setPropertyAsSwipedTo,
  setSelectedPlaceId,
  setRandomAndSearchPropertyLikedStatus,
  setRandomAndSearchPropertyOwner,
  setIsTraditionalSearchingFailure,
  setIsAiSearchingFailure,
  setAllRandomAndSearchPropertyLikedStatusAndOwner,
  setLastSearchValues,
  setIsFilterSearching,
} = slice.actions;

export default slice.reducer;
