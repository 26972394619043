const selling_choices = [
  {
    id: 1,
    title: "Nesting",
    description: "I'm settling into my home",
  },
  {
    id: 2,
    title: "Investing",
    description: "I'm upgrading my home",
  },
  {
    id: 3,
    title: "Resting",
    description: "I'm enjoying my home",
  },
  {
    id: 4,
    title: "Questing",
    description: "Searching for a new home",
  },
];

export default selling_choices;
