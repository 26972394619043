import {
  MdOutlineBathtub,
  MdOutlineBed,
  MdOutlineDirectionsCar,
  MdOutlineSquareFoot,
  MdOutlineStairs,
} from "react-icons/md";
import BedroomsIcon from "@src/assets/svgs/BedroomsIcon.jsx";
import BathroomsIcon from "@src/assets/svgs/BathroomsIcon.jsx";
import SquareFeetIcon from "@src/assets/svgs/SquareFeetIcon.jsx";
import square_feet from "./square_feet";

// This array is currently based on the properties that come back with a home from the back end
const home_details = [
  {
    id: "bedrooms",
    owner_id: "owner_given_bedrooms",
    icon: BedroomsIcon,
    labelSingular: "bed",
    labelPlural: "beds",
    min: 1,
  },
  {
    id: "bathrooms",
    owner_id: "owner_given_bathrooms",
    icon: BathroomsIcon,
    labelSingular: "bath",
    labelPlural: "baths",
    min: 1,
    interval: 0.5,
  },
  {
    id: "building_area",
    owner_id: "owner_given_area",
    icon: SquareFeetIcon,
    labelSingular: "sqft",
    labelPlural: "sqft",
    min: square_feet.min,
    max: square_feet.max,
    interval: 25,
    roundTo: 25,
    isInputWithSlider: true,
    placeholder: "Building area",
  },
  // {
  //   id: "stories",
  //   icon: MdOutlineStairs,
  //   labelSingular: "story",
  //   labelPlural: "stories",
  //   min: 1,
  // },
];

export default home_details;
