import React from "react";

function PropertyProfileSubheading({ property, className }) {
  if (!property.owner_headline)
    return (
      <p
        className={`${className} capitalize`}
      >{`${property.city ? property.city.toLowerCase() + ", " : " "}${property.state ? property.state + " " : ""}${property?.zip ? property.zip : ""}`}</p>
    );
  return (
    <p className="leading-4">
      <span
        className={`${className} capitalize`}
      >{`${property?.address_line_1?.toLowerCase()}`}</span>
      <br />
      <span
        className={`${className} capitalize`}
      >{`${property.city ? property.city.toLowerCase() + ", " : " "}${property.state ? property.state + " " : ""}${property?.zip ? property.zip : ""}`}</span>
    </p>
  );
}

export default PropertyProfileSubheading;
