// architecturalStyles.js
import artDecoImage from "../assets/architectural_styles/art_deco.jpeg";
import bungalowImage from "../assets/architectural_styles/bungalow.webp";
import capeCodImage from "../assets/architectural_styles/capecod.webp";
import colonialImage from "../assets/architectural_styles/colonial.webp";
import contemporaryImage from "../assets/architectural_styles/contemporary.webp";
import craftsmanImage from "../assets/architectural_styles/craftsman.webp";
import creoleImage from "../assets/architectural_styles/creole.webp";
import dutchColonialImage from "../assets/architectural_styles/dutch_colonial.webp";
import federalImage from "../assets/architectural_styles/federal.webp";
import frenchProvincialImage from "../assets/architectural_styles/french_provincial.webp";
import georgianImage from "../assets/architectural_styles/georgian.webp";
import gothicRevivalImage from "../assets/architectural_styles/gothic_revival.webp";
import greekRevivalImage from "../assets/architectural_styles/greek_revival.webp";
import internationalImage from "../assets/architectural_styles/international.webp";
import italianateImage from "../assets/architectural_styles/italianate.webp";
import montereyImage from "../assets/architectural_styles/monterey.webp";
import nationalImage from "../assets/architectural_styles/national.webp";
import neoclassicalImage from "../assets/architectural_styles/neoclassical.webp";
import prairieImage from "../assets/architectural_styles/prairie.webp";
import puebloImage from "../assets/architectural_styles/pueblo.webp";
import queenAnneImage from "../assets/architectural_styles/queenanne.webp";
import ranchImage from "../assets/architectural_styles/ranch.webp";
import regencyImage from "../assets/architectural_styles/regency.webp";
import saltboxImage from "../assets/architectural_styles/saltbox.webp";
import secondEmpireImage from "../assets/architectural_styles/second_empire.webp";
import shedImage from "../assets/architectural_styles/shed.webp";
import shingleImage from "../assets/architectural_styles/shingle.webp";
import shotgunImage from "../assets/architectural_styles/shotgun.webp";
import spanishEclecticImage from "../assets/architectural_styles/spanish_eclectic.webp";
import splitLevelImage from "../assets/architectural_styles/split_level.webp";
import stickImage from "../assets/architectural_styles/stick.webp";
import tudorImage from "../assets/architectural_styles/tudor.webp";
import victorianImage from "../assets/architectural_styles/victorian.webp";

const architecturalStyles = [
  // { id: "art_deco", label: "Art Deco", image: artDecoImage },
  { id: 13, label: "Bungalow", image: bungalowImage, mapping: [13] },
  { id: 14, label: "Cape Cod", image: capeCodImage, mapping: [14] },
  {
    id: 7,
    label: "Colonial",
    image: colonialImage,
    mapping: [7, 51, 57, 64, 77],
  },
  { id: 8, label: "Contemporary", image: contemporaryImage, mapping: [8] },
  // { id: "craftsman", label: "Craftsman", image: craftsmanImage, mapping: [] },
  // { id: "creole", label: "Creole", image: creoleImage },
  {
    id: 64,
    label: "Dutch Colonial",
    image: dutchColonialImage,
    mapping: [7, 51, 57, 64, 77],
  },
  {
    id: 77,
    label: "Federal",
    image: federalImage,
    mapping: [7, 51, 57, 64, 77],
  },
  {
    id: 6,
    label: "French Provincial",
    image: frenchProvincialImage,
    mapping: [4, 5, 6, 60, 90],
  },
  { id: 110, label: "Georgian", image: georgianImage, mapping: [110] },
  {
    id: 61,
    label: "Gothic Revival",
    image: gothicRevivalImage,
    mapping: [12, 15, 26, 30, 61, 75, 83, 91],
  },
  // { id: "greek_revival", label: "Greek Revival", image: greekRevivalImage, mapping: [] },
  // { id: "international", label: "International", image: internationalImage, mapping: [] },
  // { id: "italianate", label: "Italianate", image: italianateImage, mapping: [] },
  //{ id: "monterey", label: "Monterey", image: montereyImage, mapping: [] },
  // { id: "national", label: "National", image: nationalImage, mapping: [] },
  // { id: "neoclassical", label: "Neoclassical", image: neoclassicalImage, mapping: [] },
  // { id: "prairie", label: "Prairie", image: prairieImage, mapping: [] },
  // { id: "pueblo", label: "Pueblo", image: puebloImage, mapping: [] },
  // { id: "queen_anne", label: "Queen Anne", image: queenAnneImage, mapping: [] },
  { id: 11, label: "Ranch", image: ranchImage, mapping: [20, 11, 27, 103] },
  // { id: "regency", label: "Regency", image: regencyImage, mapping: [] },
  {
    id: 83,
    label: "Saltbox",
    image: saltboxImage,
    mapping: [12, 15, 26, 30, 61, 75, 83, 91],
  },
  // { id: "second_empire", label: "Second Empire", image: secondEmpireImage, mapping: [] },
  // { id: "shed", label: "Shed", image: shedImage, mapping: [] },
  //{ id: "shingle", label: "Shingle", image: shingleImage, mapping: [] },
  { id: 153, label: "Shotgun", image: shotgunImage, mapping: [153] },
  {
    id: 4,
    label: "Spanish Eclectic",
    image: spanishEclecticImage,
    mapping: [4, 5, 6, 60, 90],
  },
  {
    id: 17,
    label: "Split Level",
    image: splitLevelImage,
    mapping: [16, 17, 125, 126],
  },
  // {id: "stick", label: "Stick", image: stickImage, mapping: [] },
  { id: 52, label: "Tudor", image: tudorImage, mapping: [52] },
  { id: 18, label: "Victorian", image: victorianImage, mapping: [18] },
  { id: 12, label: "Other", image: nationalImage, mapping: [12] },
];

export default architecturalStyles;
