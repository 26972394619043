import React, { useEffect, useRef, useState } from "react";
import { formatNumber, unformatNumber } from "@src/utils/misc.js";

function PropertyProfileEditorCurrencyInput({
  id,
  title,
  value = "",
  onChange,
  placeholder = "",
}) {
  const inputRef = useRef(null);
  const [inputValue, setInputValue] = useState("");

  // Sync the prop value with internal state whenever the value changes
  useEffect(() => {
    setInputValue(value);
  }, [value]);

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
    setInputValue(`$${formatNumber(value)}`);
  }, []);

  const handleInputChange = (e) => {
    const { value } = e.target;

    // Only allow numeric input
    const numericValue = value.replace(/[^0-9.]/g, ""); // This keeps numbers and the dot (for decimals)

    // Update internal state with formatted value
    if (!numericValue) setInputValue("$0");
    else setInputValue(`$${formatNumber(numericValue)}`);

    // Call the parent onChange handler with the raw numeric value
    if (onChange) {
      onChange(unformatNumber(numericValue)); // Remove commas before passing the value to the parent
    }
  };

  return (
    <>
      <h1 className="mt-36 text-balance p-4 px-8 pt-0 text-center font-attention text-2xl font-light leading-[130%] text-neutral-600 sm:text-3xl md:pt-8">
        {title}
      </h1>
      <div className="mt-10 flex w-[min(calc(100%-5rem),12rem)] flex-row items-center overflow-hidden rounded-md ring-1 ring-neutral-300 transition-all focus:ring-primary-500">
        <input
          ref={inputRef}
          onChange={handleInputChange}
          value={inputValue}
          placeholder={placeholder}
          className="min-w-0 flex-1 p-3 pl-0 text-center font-content text-xl text-neutral-900 outline-none"
          id={id}
        />
      </div>
    </>
  );
}

export default PropertyProfileEditorCurrencyInput;
