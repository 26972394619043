import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { setUserInfo, setUserToken } from "../store/features/user/slice";
import { getLoggedInUserInfo } from "@src/api/user";
import { getAuthTokenFromStorage } from "@src/utils/misc";

export const Initializer = ({ children }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    const getUser = async () => {
      try {
        const response = await getLoggedInUserInfo();
        dispatch(setUserInfo(response));
        dispatch(setUserToken(token));
      } catch (error) {
        console.log(error);
      }
    };
    const token = getAuthTokenFromStorage();
    if (token) getUser();
  }, []);

  return children;
};
