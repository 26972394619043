import React, { useEffect, useState } from "react";
import {
  useParams,
  useNavigate,
  useSearchParams,
  NavLink,
} from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { isFulfilled } from "@reduxjs/toolkit";
// import NotFound from "../notFound/NotFound.jsx";
import { AnimatePresence, motion } from "framer-motion";
import SearchSpinner from "@src/components/searchSpinner/SearchSpinner.jsx";
import PropertyProfileMasthead from "@src/components/propertyProfile/propertyProfileHeroSection/PropertyProfileMasthead.jsx";
import PropertyProfilePerksDisplay from "@src/components/propertyProfile/propertyProfilePerksDisplay/PropertyProfilePerksDisplay.jsx";
import PropertyProfileDescription from "@src/components/propertyProfile/propertyProfileDescription/PropertyProfileDescription.jsx";
import PropertyProfileDetailsGrid from "@src/components/propertyProfile/propertyProfileDetailsGrid/PropertyProfileDetailsGrid.jsx";
import { MdArrowBack, MdOutlineEdit, MdOutlineOpenInNew } from "react-icons/md";
import { SIGNIN_MODAL_TYPE } from "@src/utils/constants.js";
import { toast } from "react-toastify";
import SignInModal from "@src/components/signInModal/SignInModal.jsx";
import PropertyProfileEditor from "@src/components/propertyProfile/propertyProfileEditor/PropertyProfileEditor.jsx";

import PropertyProfileCallsToAction from "@src/components/propertyProfile/propertyProfileCallsToAction/PropertyProfileCallsToAction.jsx";

import PropertyProfileSiteLinks from "@src/components/propertyProfile/propertyProfileSiteLinks/PropertyProfileSiteLinks.jsx";
import PropertyProfileMap from "@src/components/propertyProfile/propertyProfileMap/PropertyProfileMap.jsx";
import { setEditProfilePromptLastShown } from "@src/store/features/user/slice.js";

function PropertyProfileContent({ property, display = "page" }) {
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [notFound, setNotFound] = useState(false);
  // const { property } = useSelector((state) => state.property);
  const isLoggedIn = useSelector((state) => state.user.isLoggedIn);
  const loggedInUser = useSelector((state) => state.user.user);
  const [signinModalType, setSigninModalType] = useState("");
  const [redirectUrl, setRedirectUrl] = useState("");
  const [isEditing, setIsEditing] = useState(false);
  const [hasPerks, setHasPerks] = useState(false);
  const [hasDescription, setHasDescription] = useState(false);
  const [latitude, setLatitude] = useState(null);
  const [longitude, setLongitude] = useState(null);

  const showMainContent =
    hasPerks || hasDescription || (latitude !== null && longitude !== null)
      ? true
      : false;
  const isOwner = property?.owner_id === loggedInUser?.id;

  useEffect(() => {
    if (!searchParams.get("edit")) {
      setIsEditing(false);
    } else {
      setIsEditing(true);
    }
  }, [searchParams]);

  function handleEdit() {
    const newSearchParams = new URLSearchParams(searchParams);
    const hasEditParam = searchParams.get("edit");

    if (hasEditParam) {
      newSearchParams.delete("edit");
      setIsEditing(false);
    } else {
      newSearchParams.set("edit", "true");
      setIsEditing(true);
    }

    setSearchParams(newSearchParams);
    dispatch(setEditProfilePromptLastShown(JSON.stringify(new Date())));
  }

  const handleChat = () => {
    setRedirectUrl(`/conversations/create/${property?.id}`);
    if (!isLoggedIn) {
      setSigninModalType(SIGNIN_MODAL_TYPE.CHAT);
      return;
    }

    if (property?.owner_id !== loggedInUser.id)
      navigate(`/conversations/create/${property?.id}`);
    else toast.error("You own this property!");
  };

  const onClickSignModalOverlay = () => {
    setSigninModalType("");
    setRedirectUrl("");
  };

  useEffect(() => {
    if (property?.owner_perks) {
      setHasPerks(true);
    }
    if (property?.owner_blurb) {
      setHasDescription(true);
    }

    if (property?.latitude && property?.longitude) {
      setLatitude(property.latitude);
      setLongitude(property.longitude);
    }
  }, [property]);

  return (
    <div className="min-h-full">
      {property ? (
        <div
          className={`mx-auto h-full min-h-dvh bg-white ${display === "page" && "max-w-5xl md:px-6 md:pt-20"}`}
        >
          {display === "page" && (
            <div className="fixed left-0 top-0 z-10 hidden h-20 w-full bg-white/90 md:block"></div>
          )}

          {display === "modal" ? (
            <header className="relative h-[50dvh] overflow-hidden md:max-h-[500px]">
              <PropertyProfileMasthead property={property} />
              {display === "modal" && (
                <button
                  className="absolute left-3 top-3 flex items-center gap-2 rounded-full bg-black/50 p-2.5 text-white/80 duration-300 md:hover:bg-black/80"
                  onClick={() => {
                    //remove the the showing property from the search params
                    setSearchParams({});
                  }}
                >
                  <MdArrowBack className="h-5 w-5 fill-current" />
                  {/*<img className="w-6 fill-current" src={menuIcon} />*/}
                </button>
              )}
            </header>
          ) : (
            <header className="h-[60dvh] overflow-hidden md:max-h-[500px] md:rounded-xl">
              <PropertyProfileMasthead property={property} />
            </header>
          )}

          <main className={`pb-24 ${display === "page" && "md:px-0"}`}>
            <div
              className={`mb-3 p-4 ${display === "page" && "md:px-0 md:pt-6"}`}
            >
              <PropertyProfileDetailsGrid
                textColor="text-black"
                fontSize="text-sm"
                property={property}
              />
            </div>
            <div
              className={`flex flex-col gap-10 px-4 md:flex-row ${display === "page" && "md:px-0"}`}
            >
              {/*{showMainContent && (*/}
              <section data-main className={`flex basis-3/4 flex-col gap-8`}>
                {hasPerks && (
                  <div className="">
                    <PropertyProfilePerksDisplay property={property} />
                  </div>
                )}
                {hasDescription && (
                  <PropertyProfileDescription property={property} />
                )}
                {latitude !== null && longitude !== null && (
                  <div>
                    <PropertyProfileMap property={property} />
                  </div>
                )}
              </section>
              {/*)}*/}

              <aside
                data-sidebar
                className="flex w-full min-w-fit flex-grow basis-1/3 flex-col gap-4"
              >
                {!isOwner && (
                  <div
                    className={`${!showMainContent && "mt-16"} mx-auto hidden w-full max-w-2xl rounded-lg bg-white md:flex md:ring-1 md:ring-primary-500/40`}
                  >
                    <PropertyProfileCallsToAction property={property} />
                  </div>
                )}
                <PropertyProfileSiteLinks property={property} />
              </aside>
            </div>

            {/* Add Google Map here */}
          </main>
          {!isOwner && (
            <div className="sticky bottom-0 left-0 w-full bg-white shadow-[0px_-8px_12px_rgba(100,100,100,0.125)] md:hidden">
              <PropertyProfileCallsToAction property={property} />
            </div>
          )}
        </div>
      ) : (
        <AnimatePresence>
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className="absolute left-0 top-0 h-full w-full bg-white/90"
          >
            <div className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2">
              <SearchSpinner label="Fetching property..." />
            </div>
          </motion.div>
        </AnimatePresence>
      )}
      {signinModalType ? (
        <SignInModal
          isOpen={!!signinModalType}
          handleClose={onClickSignModalOverlay}
          redirectTo={redirectUrl}
          type={signinModalType}
        />
      ) : null}

      <AnimatePresence>
        {isEditing ? <PropertyProfileEditor property={property} /> : null}
      </AnimatePresence>
      {loggedInUser?.id === property?.owner_id && (
        <div className="pointer-events-none fixed bottom-0 left-1/2 z-20 mx-auto w-full max-w-5xl -translate-x-1/2 md:px-6 md:pt-20">
          <button
            className={`pointer-events-auto absolute bottom-5 right-8 flex h-12 ${isEditing ? "w-fit px-6" : "w-12"} items-center justify-center rounded-full bg-black/80 shadow-md transition-all duration-300`}
            onClick={handleEdit}
          >
            {isEditing ? (
              <span className="text-white">Done</span>
            ) : (
              <MdOutlineEdit className="h-6 w-6 fill-white" />
            )}
          </button>
        </div>
      )}
    </div>
  );
}

export default PropertyProfileContent;
