import PropertyProfileContent from "@src/components/propertyProfile/propertyProfileContent/PropertyProfileContent";
import { useParams, useSearchParams } from "react-router-dom";

function PropertyProfileModal({ property }) {
  const [searchParams, setSearchParams] = useSearchParams();
  function handleOverlayClick(e) {
    const newSearchParams = new URLSearchParams(searchParams);
    const hasShowingParam = searchParams.get("showing");

    if (hasShowingParam) {
      newSearchParams.delete("showing");
    }

    setSearchParams(newSearchParams);
  }

  return (
    <div className="relative h-full w-full overflow-y-auto md:p-5">
      <div
        className="fixed left-0 top-0 z-0 h-full w-full py-10"
        onClick={handleOverlayClick}
      ></div>
      <div className="shadow-3xl relative z-10 mx-auto min-h-full w-full max-w-5xl shadow-xl shadow-black/40 md:overflow-hidden md:rounded-xl">
        <PropertyProfileContent display="modal" property={property} />
      </div>
    </div>
  );
}

export default PropertyProfileModal;
