import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

import {
  useStripe,
  useElements,
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
} from "@stripe/react-stripe-js";

import {
  addProposalVoucher,
  completeProposalWithZeroPayment,
  removeProposalVoucher,
} from "@src/api/proposal";
import OverlayLoader from "@src/components/overlayLoader/OverlayLoader";
import TermsOfUseModal from "@src/components/termsOfUseModal/TermsOfUseModal";
import { SHOW_CONVERSATION_SUCCESS } from "@src/utils/constants";
import { motion } from "framer-motion";
import { IoMdCheckmark } from "react-icons/io";

export default function PaymentCardDetails({
  proposalId,
  paymentId,
  price,
  setCurrentProposalData,
  currentAddedVoucher,
  selectedPackageLabel,
}) {
  const navigate = useNavigate();

  const [isProcessing, setProcessingTo] = useState(false);
  const [firstTimeBound, setFirstTimeBound] = useState(true);

  const [nameValue, setNameValue] = useState("");
  const [voucherValue, setVoucherValue] = useState(currentAddedVoucher || "");
  const [isCheckingVoucher, setIsCheckingVoucher] = useState(false);
  const [nameValueValidation, setNameValueValidation] = useState("");

  const [isCardValid, setIsCardValid] = useState(false);
  const [isCardExpiryValid, setIsCardExpiryValid] = useState(false);
  const [isCardCvcValid, setIsCardCvcValid] = useState(false);

  const [cardNumInputValidation, setCardNumInputValidation] = useState("");
  const [cardExpiryInputValidation, setCardExpiryInputValidation] =
    useState("");
  const [cardCvcInputValidation, setCardCvcInputValidation] = useState("");

  const [isTermsOfUseVisible, setIsTermsOfUseVisible] = useState(false);

  const stripe = useStripe();
  const elements = useElements();

  let cardElement;
  let cardExpiryElement;
  let cardCvcElement;

  const handleNameField = (name) => {
    setNameValueValidation("");
    setNameValue(name);
    if (!name) {
      setNameValueValidation("Please enter full name!");
    } else if (!String(name).match(/^[a-zA-Z() ]+$/)) {
      setNameValueValidation(
        "Please enter valid name. Only alphabets allowed!",
      );
    } else if (name.length > 80) {
      setNameValueValidation("Please enter upto 80 alphabets only!");
    }
  };

  const handleVoucherField = (voucher) => {
    setVoucherValue(voucher);
  };

  const handleFormWithPayment = async () => {
    setProcessingTo(true);

    if (!stripe || !elements || !paymentId) {
      setProcessingTo(false);
      return;
    }

    cardElement = elements.getElement(CardNumberElement);

    try {
      // Creating Payment Method
      const paymentMethodResponse = await stripe.createPaymentMethod({
        type: "card",
        card: cardElement,
      });
      if (paymentMethodResponse.error) {
        toast.error(paymentMethodResponse.error.message);
        setProcessingTo(false);
        return;
      }

      // Confirming Payment / Making Payment against Payment Intent
      const confirmPaymentResponse = await stripe.confirmCardPayment(
        paymentId,
        {
          payment_method: paymentMethodResponse.paymentMethod.id,
        },
      );
      if (confirmPaymentResponse.error) {
        toast.error(confirmPaymentResponse.error.message);
        setProcessingTo(false);
        return;
      }

      localStorage.setItem(SHOW_CONVERSATION_SUCCESS, JSON.stringify(true));
      navigate(`/conversation/${proposalId}`);
    } catch {
      toast.error("We couldn't confirm your payment. Try again?");
    }
    setProcessingTo(false);
  };

  const handleFormWithZeroPayment = async () => {
    setProcessingTo(true);
    try {
      await completeProposalWithZeroPayment(proposalId);

      navigate(`/conversation/${proposalId}`);
    } catch {
      toast.error("We couldn't confirm your payment. Try again?");
    }
    setProcessingTo(false);
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    if (price) {
      handleFormWithPayment();
    } else {
      handleFormWithZeroPayment();
    }
  };

  const addStripeCardElementsValidations = () => {
    // Card Number Input Validator
    cardElement.on("change", (event) => {
      setIsCardValid(!event.empty && !event.error && event.complete);
      if (event.error) {
        setCardNumInputValidation(event.error.message);
      } else {
        setCardNumInputValidation("");
      }
    });

    // Card Expiry Input Validator
    cardExpiryElement.on("change", (event) => {
      setIsCardExpiryValid(!event.empty && !event.error && event.complete);
      if (event.error) {
        setCardExpiryInputValidation(event.error.message);
      } else {
        setCardExpiryInputValidation("");
      }
    });

    // Card CVC Input Validator
    cardCvcElement.on("change", (event) => {
      setIsCardCvcValid(!event.empty && !event.error && event.complete);
      if (event.error) {
        setCardCvcInputValidation(event.error.message);
      } else {
        setCardCvcInputValidation("");
      }
    });

    setFirstTimeBound(false);
  };

  useEffect(() => {
    if (stripe && elements && firstTimeBound && price) {
      cardElement = elements.getElement(CardNumberElement);
      cardExpiryElement = elements.getElement(CardExpiryElement);
      cardCvcElement = elements.getElement(CardCvcElement);

      addStripeCardElementsValidations();
    }
  }, [stripe, elements]);

  const addVoucher = async () => {
    setIsCheckingVoucher(true);
    try {
      const response = await addProposalVoucher({
        proposalId,
        voucherCode: voucherValue,
      });
      setCurrentProposalData(response);
      toast.success("Hurray! Your voucher has been added");
    } catch (error) {
      toast.error("Invalid promo code");
    }
    setIsCheckingVoucher(false);
  };

  const removeVoucher = async () => {
    if (isCheckingVoucher) return;
    setIsCheckingVoucher(true);
    try {
      const response = await removeProposalVoucher(proposalId);
      setCurrentProposalData(response);
      setVoucherValue("");
      toast.success("Your voucher has been removed");
    } catch (error) {
      toast.error("Could not remove voucher");
    }
    setIsCheckingVoucher(false);
  };

  const paymentButton = (
    <div
      className={`bg-white px-5 py-5 shadow-[0px_-5px_12px_rgba(0,0,0,0.2)] md:relative md:bg-transparent md:shadow-none ${price && "fixed bottom-0 left-0 w-full md:p-0"}`}
    >
      <p className="font-content font-semibold">Total Price: {`$${price}`}</p>
      <button
        type="submit"
        className={`relative mt-2 flex w-full cursor-pointer justify-center gap-2 rounded-md bg-neutral-600 p-3 px-6 text-sm font-semibold text-white shadow-md disabled:pointer-events-none disabled:opacity-40 disabled:shadow-none ${price && "sm:max-w-fit"}`}
        disabled={
          (!stripe ||
            isProcessing ||
            !(isCardValid && isCardExpiryValid && isCardCvcValid) ||
            !nameValue ||
            nameValue.length > 80 ||
            !String(nameValue).match(/^[a-zA-Z() ]+$/)) &&
          price
        }
      >
        Complete payment
      </button>
      <p className="mt-3 w-fit text-xs text-neutral-600">
        By sending your package you agree to the{" "}
        <span
          className="block cursor-pointer text-sage-600 underline hover:text-sage-800"
          onClick={() => setIsTermsOfUseVisible(true)}
        >
          terms and conditions.
        </span>
      </p>
    </div>
  );

  return (
    <form
      onSubmit={handleFormSubmit}
      className="mb-32 mt-3 overflow-hidden rounded-md shadow-md ring-1 ring-neutral-300"
    >
      {price ? (
        <div className="flex flex-col md:flex-row">
          {/*<p className="mt-6 text-center font-attention text-xl font-semibold">*/}
          {/*  Credit/Debit Card Details*/}
          {/*</p>*/}
          <div className="p-8 pt-6">
            <span className="text-sm text-neutral-700">
              All fields required*
            </span>
            <div className="mt-3">
              <label className="text-sm font-medium text-neutral-700">
                Name on Card
              </label>
              <input
                name="name"
                type="text"
                value={nameValue}
                onChange={(e) => handleNameField(e.target.value)}
                className="mt-1 inline-block h-11 w-full resize-none rounded-md border border-[#8E9991] p-3 py-2.5 pt-3 font-content text-base text-sm shadow-md shadow-neutral-400/10 outline-0 transition-all disabled:opacity-50"
              />
              <p className="mt-1 text-sm text-red-500">{nameValueValidation}</p>
            </div>
            <div className="mt-3 w-full">
              <label
                name="name"
                className="text-sm font-medium text-neutral-700"
              >
                Card Number
              </label>
              <CardNumberElement
                options={{ placeholder: "0000 0000 0000 0000" }}
                className="font mt-1 inline-block h-11 w-full resize-none rounded-md border border-[#8E9991] p-3 font-content shadow-md shadow-neutral-400/10 outline-0 transition-all"
              />
              <p className="mt-1 text-sm text-red-500">
                {cardNumInputValidation}
              </p>
            </div>

            <div className="mt-3 flex flex-wrap gap-4">
              <div className="min-w-20">
                <label className="text-sm font-medium text-neutral-700">
                  Expiration
                </label>
                <CardExpiryElement className="font mt-1 inline-block h-11 w-full resize-none rounded-md border border-[#8E9991] p-3 font-content shadow-md shadow-neutral-400/10 outline-0 transition-all" />
                <p className="text-red-500">{cardExpiryInputValidation}</p>
              </div>
              <div className="min-w-20">
                <label className="text-sm font-medium text-neutral-700">
                  CVC
                </label>
                <CardCvcElement className="font mt-1 inline-block h-11 w-full resize-none rounded-md border border-[#8E9991] p-3 font-content shadow-md shadow-neutral-400/10 outline-0 transition-all" />
                <p className="text-red-500">{cardCvcInputValidation}</p>
              </div>
              <div className="max-w-44">
                <label className="text-sm font-medium text-neutral-700">
                  Promo code
                </label>
                <div className="mt-1 flex gap-1">
                  <input
                    name="voucher"
                    type="text"
                    value={voucherValue}
                    onChange={(e) => handleVoucherField(e.target.value)}
                    className="inline-block h-11 w-full resize-none rounded-md border border-[#8E9991] p-3 py-2.5 pt-3 font-content text-base text-sm shadow-md shadow-neutral-400/10 outline-0 transition-all disabled:opacity-50"
                    disabled={!!currentAddedVoucher || isCheckingVoucher}
                  />
                  <button
                    type="button"
                    className="rounded-md bg-neutral-200 p-2 px-4 text-xs uppercase text-green-600 disabled:pointer-events-none disabled:opacity-40"
                    onClick={addVoucher}
                    disabled={
                      !!currentAddedVoucher ||
                      voucherValue?.length < 4 ||
                      isCheckingVoucher
                    }
                  >
                    <IoMdCheckmark className="h-5 w-5 fill-current" />
                  </button>
                </div>
                {currentAddedVoucher ? (
                  <motion.div
                    key="popup-container"
                    initial={{ opacity: 0, scale: 1, y: -10 }}
                    animate={{ opacity: 1, scale: 1, y: 0 }}
                    className="mt-1 cursor-pointer"
                    onClick={removeVoucher}
                  >
                    <span className="text-sm text-neutral-700">
                      Remove voucher
                    </span>
                  </motion.div>
                ) : null}
              </div>
            </div>
          </div>
          <div className="w-full border-t border-t-neutral-200 bg-sage-200 p-6 px-6 pb-3 text-neutral-700 md:w-fit md:border-l md:border-l-neutral-200 md:pb-6">
            <div className="flex h-full flex-col justify-between">
              <div>
                <h2 className="font-attention text-xl">Your package</h2>
                <ul className="max-w-[30ch] list-disc text-balance p-4 text-sm">
                  <li className="py-1">
                    Unlisted introduction and personal letter
                  </li>
                  <li className="py-1">{selectedPackageLabel}</li>
                </ul>
              </div>

              {paymentButton}
            </div>
          </div>
        </div>
      ) : (
        paymentButton
      )}

      {isProcessing ? <OverlayLoader /> : null}
      {isTermsOfUseVisible ? (
        <TermsOfUseModal
          open={isTermsOfUseVisible}
          onClickOverlay={() => setIsTermsOfUseVisible(false)}
        />
      ) : null}
    </form>
  );
}
