import React, { useRef, useState } from "react";
import PropertyProfileImage from "@src/components/propertyProfile/propertyProfileImage/PropertyProfileImage.jsx";
import { useDispatch } from "react-redux";
import { updatePropertyMainImage } from "@src/store/features/property/service.js";
import { setPropertyPropValue } from "@src/store/features/property/slice.js";
import {
  cacheImage,
  invalidateImage,
} from "@src/store/features/imageCache/slice.js";
import { UPLOAD_PHOTO_TYPE } from "@src/utils/constants";
import { setFirstPhotoPromptLastShown } from "@src/store/features/user/slice.js";

function PropertyProfileMainImageUpdater({ property }) {
  const [isUploading, setIsUploading] = useState(false);
  const dispatch = useDispatch();
  const fileInputRef = useRef(null);

  // Handle the image file selection
  const handleImageChange = async (event) => {
    const file = event.target.files[0];
    if (file) {
      setIsUploading(true);
      // dispatch(invalidateImage(property.id));

      await new Promise((resolve) => setTimeout(resolve, 2000));
      const response = await dispatch(
        updatePropertyMainImage({
          file: event.target.files[0],
          type: UPLOAD_PHOTO_TYPE.PROPERTY_COVER,
          propertyId: property.id,
        }),
      );
      dispatch(
        cacheImage({
          propertyId: property.id,
          imageUrl: response.payload.get_url,
        }),
      );
      if (response.payload.get_url) {
        setIsUploading(false);
        dispatch(
          setPropertyPropValue({
            key: "cover_photo_url",
            value: response.payload.get_url,
          }),
        );
      }
      dispatch(setFirstPhotoPromptLastShown(true));
    }
  };

  // Trigger the hidden file input when the button is clicked
  const handleButtonClick = () => {
    fileInputRef.current.click();
  };

  return (
    <div className="relative h-full w-full overflow-hidden rounded-xl">
      <PropertyProfileImage property={property} />
      <div className="absolute inset-0 flex h-full flex-col items-center justify-center bg-black/20 p-4 text-left">
        {isUploading ? (
          <>
            <div className="absolute inset-0 flex animate-pulse flex-col items-center justify-center gap-2 bg-black/80 text-neutral-300">
              <div className="border-r-red h-8 w-8 animate-spin rounded-full border-4 border-neutral-600 border-r-primary-500"></div>
              Updating image...
            </div>
          </>
        ) : (
          <button
            className="rounded-lg border border-neutral-300 bg-black/40 px-5 py-4 text-sm font-normal text-neutral-100"
            onClick={handleButtonClick}
          >
            Change Main Photo
          </button>
        )}

        <input
          type="file"
          accept="image/*"
          ref={fileInputRef}
          onChange={handleImageChange}
          style={{ display: "none" }}
        />
      </div>
    </div>
  );
}

export default PropertyProfileMainImageUpdater;
