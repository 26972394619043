import React, { useEffect, useState } from "react";
import { useSearchParams, useNavigate } from "react-router-dom";
import PropertyProfileImage from "@src/components/propertyProfile/propertyProfileImage/PropertyProfileImage.jsx";
import PropertyProfileEstimatedValueTag from "@src/components/propertyProfile/propertyProfileEstimatedValueTag/PropertyProfileEstimatedValueTag.jsx";
import PropertyProfileHeadline from "@src/components/propertyProfile/propertyProfileHeadline/PropertyProfileHeadline.jsx";
import PropertyProfileSubheading from "@src/components/propertyProfile/propertyProfileSubheading/PropertyProfileSubheading.jsx";
import PropertyProfileDetails from "@src/components/propertyProfile/propertyProfileDetails/PropertyProfileDetails.jsx";
import PropertyProfileChatButton from "@src/components/propertyProfile/propertyProfileButtons/PropertyProfileChatButton.jsx";
import PropertyProfileFavoriteButton from "@src/components/propertyProfile/propertyProfileButtons/PropertyProfileFavoriteButton.jsx";
import { MdFlag, MdOutlineIosShare } from "react-icons/md";
import { useSelector } from "react-redux";
import { SIGNIN_MODAL_TYPE } from "@src/utils/constants.js";
import { toast } from "react-toastify";
import ClaimHomeVerificationModal from "@src/components/propertyProfile/propertyProfileContent/components/claimHomeVerificationModal/ClaimHomeVerificationModal.jsx";
import SignInModal from "@src/components/signInModal/SignInModal.jsx";
import PropertyProfileClaimedBadge from "@src/components/propertyProfile/propertyProfileClaimedBadge/PropertyProfileClaimedBadge.jsx";

export default function PropertyProfileMasthead({ property }) {
  const navigate = useNavigate();
  //const streetViewUrl = `https://maps.googleapis.com/maps/api/streetview?size=640x640&location=${property.latitude},${property.longitude}&fov=80&heading=70&pitch=0&scale=2&key=${import.meta.env.VITE_GOOGLE_MAPS_API_KEY}`;
  const [searchParams, setSearchParams] = useSearchParams();
  const shouldClaimHome = searchParams.get("claimHome");
  const isLoggedIn = useSelector((state) => state.user.isLoggedIn);
  const loggedInUser = useSelector((state) => state.user.user);
  const homes = useSelector((state) => state.user.homes);

  const [isEditing, setIsEditing] = useState(false);
  const [signinModalType, setSigninModalType] = useState("");
  const [redirectUrl, setRedirectUrl] = useState("");
  const [isClaimHomeModalVisible, setIsClaimHomeModalVisible] = useState(false);

  const handleShare = async () => {
    if (navigator.share) {
      try {
        await navigator.share({
          title: property.address_line_1,
          text: `Check out this property at ${property.address_line_1}, ${property.city}, ${property.state}`,
          url: `${window.location.origin}/profile/${property.id}`,
        });
      } catch (error) {
        console.error("Error sharing", error);
      }
    } else {
      alert("Sharing is not supported in this browser.");
    }
  };

  const onClickSignModalOverlay = () => {
    setSigninModalType("");
    setRedirectUrl("");
  };

  const onClickClaimHomeModalOverlay = () => {
    setIsClaimHomeModalVisible(false);
    setRedirectUrl("");
  };

  const handleClaimHome = () => {
    if (!isLoggedIn) {
      setRedirectUrl(`/profile/${property.id}?claimHome=true`);
      setSigninModalType(SIGNIN_MODAL_TYPE.PROPERTY);
      return;
    }
    setIsClaimHomeModalVisible(true);
  };

  function handleEdit() {
    const newSearchParams = new URLSearchParams(searchParams);
    const hasEditParam = searchParams.get("edit");

    if (hasEditParam) {
      newSearchParams.delete("edit");
      setIsEditing(false);
    } else {
      newSearchParams.set("edit", "true");
      setIsEditing(true);
    }

    setSearchParams(newSearchParams);
  }

  useEffect(() => {
    if (shouldClaimHome && loggedInUser?.id) {
      setSearchParams({});
      if (!property?.owner_id) setIsClaimHomeModalVisible(true);
      else if (property?.owner_id === loggedInUser?.id) {
        toast.error("You already own this property!");
      }
    }
  }, [loggedInUser?.id]);

  return (
    <div className="relative h-full">
      <div className="h-full w-full">
        <PropertyProfileImage property={property} />
      </div>

      <div className="absolute bottom-0 left-0 right-0 flex h-full flex-col items-start justify-end bg-gradient-to-t from-black/70 via-black/40 via-20% to-transparent p-6 pb-6 text-left">
        <div className="flex w-full flex-col justify-between gap-3 sm:flex-row">
          <div>
            <PropertyProfileEstimatedValueTag property={property} />

            <PropertyProfileHeadline
              property={property}
              className="mb-1 mt-2 font-attention text-2xl font-normal capitalize text-white md:text-3xl"
            />
            <PropertyProfileSubheading
              property={property}
              className="mt-2 text-sm text-white"
            />
            {/*<div className="mt-0">*/}
            {/*  <PropertyProfileDetails*/}
            {/*    fontSize="text-base"*/}
            {/*    property={property}*/}
            {/*  />*/}
            {/*</div>*/}
            <div className="mt-3 md:absolute md:right-3 md:top-0">
              <PropertyProfileClaimedBadge property={property} />
            </div>
          </div>

          <div className="flex flex-row items-end justify-start gap-3">
            <div className="flex flex-row gap-3">
              {(!isLoggedIn && !property?.owner_id) ||
              loggedInUser?.id !== property?.owner_id ? (
                <PropertyProfileFavoriteButton
                  propertyId={property?.id}
                  isLiked={property?.liked}
                />
              ) : null}
              <button
                className="flex aspect-square h-10 w-10 items-center justify-center rounded-full bg-black/60 p-1.5"
                onClick={handleShare}
              >
                <MdOutlineIosShare className="h-6 w-6 -translate-y-[1px] text-white/60" />
              </button>
            </div>

            {/*{((!isLoggedIn && !property?.owner_id) ||*/}
            {/*  (isLoggedIn && !property?.owner_id && homes.length < 1)) && (*/}
            {/*  <button*/}
            {/*    onClick={handleClaimHome}*/}
            {/*    className="ml-auto flex items-center justify-center gap-2 rounded-full bg-black/60 p-2 px-5 pl-3 text-white/60"*/}
            {/*  >*/}
            {/*    <MdFlag className="" /> <span>Claim</span>*/}
            {/*  </button>*/}
            {/*)}*/}
            {/*{loggedInUser?.id === property?.owner_id && (*/}
            {/*  <button*/}
            {/*    onClick={handleEdit}*/}
            {/*    className="ml-auto flex items-center justify-center gap-2 rounded-full bg-black/60 p-2 px-5 pl-3 text-white/60"*/}
            {/*  >*/}
            {/*    <MdFlag className="" />*/}
            {/*    <span>Edit</span>*/}
            {/*  </button>*/}
            {/*)}*/}
          </div>
        </div>
      </div>
      {signinModalType ? (
        <SignInModal
          isOpen={!!signinModalType}
          handleClose={onClickSignModalOverlay}
          redirectTo={redirectUrl}
          type={signinModalType}
        />
      ) : null}
      {isClaimHomeModalVisible ? (
        <ClaimHomeVerificationModal
          open={isClaimHomeModalVisible}
          onClickOverlay={onClickClaimHomeModalOverlay}
          propertyId={property?.id}
          property={property}
        />
      ) : null}
    </div>
  );
}
