import { useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { isFulfilled } from "@reduxjs/toolkit";
import { verifyUser } from "@src/store/features/user/service";
import OverlayLoader from "@src/components/overlayLoader/OverlayLoader";
import { getLoggedInUserInfo } from "@src/api/user";
import { setUserInfo } from "@src/store/features/user/slice";
import { AUTHENTICATION_TOKEN } from "@src/utils/constants";

function VerifyUser() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [searchParams] = useSearchParams();
  const email = (searchParams.get("email") || "").trim().replace(/ /g, "+");
  const token = searchParams.get("amp;token") || searchParams.get(AUTHENTICATION_TOKEN);
  const redirectTo =
    searchParams.get("amp;redirect_to") || searchParams.get("redirect_to");

  const isLoggedIn = useSelector((state) => state.user.isLoggedIn);

  const handleOTPStep = async () => {
    const response = await dispatch(
      verifyUser({
        email,
        token,
      }),
    );

    if (isFulfilled(response)) {
      try {
        const response = await getLoggedInUserInfo();
        await dispatch(setUserInfo(response));
      } catch (error) {
        console.log(error);
      }
    } else console.log(response.payload);

    navigate(redirectTo || "/");
  };

  useEffect(() => {
    if (!isLoggedIn && token && email) {
      handleOTPStep();
    } else {
      navigate(redirectTo || "/");
    }
  }, []);

  return (
    <div className="mt-[96px]">
      <OverlayLoader />
    </div>
  );
}

export default VerifyUser;
