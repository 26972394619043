import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import * as Sentry from "@sentry/react";
import Hotjar from "@hotjar/browser";
import ReactGA from "react-ga4";

import {
  getUsersClaimedHomes,
  getUsersLikedHomes,
  updateUserDetails,
  verifyUser,
} from "./service";
import { AUTHENTICATION_TOKEN, USER_ID } from "@src/utils/constants";
import { version } from "@src/../package.json";

let cardsOnboardingComplete = JSON.parse(
  localStorage.getItem("cardsOnboardingComplete"),
);

let onboardingSlideshowComplete = JSON.parse(
  localStorage.getItem("onboardingSlideshowComplete"),
);

let editProfilePromptLastShown = localStorage.getItem(
  "editProfilePromptLastShown",
);

let firstPhotoPromptLastShown = localStorage.getItem(
  "firstPhotoPromptLastShown",
);

const initialState = {
  user: {},
  homes: [],
  likedHomes: [],
  hasMoreLikedHomes: true,
  isLoadingLikedHomes: false,
  numberOfHomesUnliked: 0,
  isLoggedIn: false,
  isLoading: false,
  isUpdatingUserDetails: false,
  token: null,
  appVersion: version,
  // eslint-disable-next-line no-undef
  appBuildDate: __BUILD_DATE__,
  isRunningServiceWorker: false,
  isRunningInStandAlone:
    window.navigator.standalone ||
    window.matchMedia("(display-mode: standalone)").matches,
  isRunningInNativeApp: false,
  cardsOnboardingComplete: cardsOnboardingComplete || false,
  onboardingSlideshowComplete: onboardingSlideshowComplete || false,
  editProfilePromptLastShown: editProfilePromptLastShown || false,
  firstPhotoPromptLastShown: firstPhotoPromptLastShown || null,
};

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUserToken: (state, { payload }) => {
      state.token = payload;
      state.isLoggedIn = true;
      localStorage.setItem(AUTHENTICATION_TOKEN, JSON.stringify(payload));
    },

    resetTours: (state) => {
      localStorage.removeItem("cardsOnboardingComplete");
      localStorage.removeItem("onboardingSlideshowComplete");
      localStorage.removeItem("editProfilePromptLastShown");
      localStorage.removeItem("firstPhotoPromptLastShown");
      document.location.reload();
    },

    setCardsOnboardingComplete: (state, { payload }) => {
      state.cardsOnboardingComplete = payload;
      localStorage.setItem("cardsOnboardingComplete", payload);
    },

    setOnboardingSlideshowComplete: (state, { payload }) => {
      state.onboardingSlideshowComplete = payload;
      localStorage.setItem("onboardingSlideshowComplete", payload);
    },

    setEditProfilePromptLastShown: (state, { payload }) => {
      state.editProfilePromptLastShown = payload;
      if (payload) {
        localStorage.setItem("editProfilePromptLastShown", payload);
      }
    },

    setFirstPhotoPromptLastShown: (state, { payload }) => {
      console.log("setFirstPhotoPromptLastShown payload", payload);
      state.firstPhotoPromptLastShown = payload;
      if (payload) {
        localStorage.setItem("firstPhotoPromptLastShown", payload);
      }
    },

    setUserInfo: (state, { payload }) => {
      state.user = payload;
      if (payload.id) {
        localStorage.setItem(USER_ID, payload.id);
      }
      Sentry.setUser({
        email: payload.email,
        id: payload.id,
      });

      Hotjar.identify(payload.id, {
        email: payload.email,
      });

      ReactGA.gtag("set", "user_properties", {
        user_id: payload.id,
      });
    },

    removeUserInfo: (state) => {
      state.user = initialState.user;
      state.homes = initialState.homes;
      state.isLoggedIn = false;
      state.token = initialState.token;
      localStorage.removeItem(AUTHENTICATION_TOKEN);
      localStorage.removeItem(USER_ID);
    },
    setIsRunningServiceWorker: (state, { payload }) => {
      state.isRunningServiceWorker = payload;
      Sentry.setTag("isRunningServiceWorker", payload);
    },
    setIsRunningInNativeApp: (state, { payload }) => {
      state.isRunningInNativeApp = payload;
      Sentry.setTag("isRunningInNativeApp", payload);
    },
    changeStatusInLikedHome: (state, { payload }) => {
      const index = state.likedHomes.findIndex(
        (item) => item?.id === payload.propertyId,
      );
      if (state.likedHomes[index]) {
        if (!payload.liked) {
          state.numberOfHomesUnliked += 1;
        } else {
          state.numberOfHomesUnliked -= 1;
        }

        state.likedHomes[index] = {
          ...state.likedHomes[index],
          liked: payload.liked,
        };
      }
    },
    resetNumberOfHomesUnliked: (state) => {
      state.numberOfHomesUnliked = 0;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(verifyUser.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(verifyUser.fulfilled, (state, { payload }) => {
        state.token = payload.token;
        state.isLoggedIn = true;
        state.isLoading = false;
        localStorage.setItem(
          AUTHENTICATION_TOKEN,
          JSON.stringify(payload.token),
        );
        state.user = { ...state.user, ...payload.userInfo };
        if (payload.userInfo?.id) {
          localStorage.setItem(USER_ID, payload.userInfo.id);
        }
      })
      .addCase(verifyUser.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(
        getUsersClaimedHomes.fulfilled,
        (state, { payload, meta: { arg } }) => {
          if (arg.page === 1) state.homes = payload.properties;
          else state.homes = [...state.homes, ...payload.properties];
        },
      )
      .addCase(getUsersLikedHomes.pending, (state) => {
        state.isLoadingLikedHomes = true;
      })
      .addCase(
        getUsersLikedHomes.fulfilled,
        (state, { payload, meta: { arg } }) => {
          state.isLoadingLikedHomes = false;
          if (arg.offset === 0) {
            state.likedHomes = payload.properties;
          } else {
            state.likedHomes = [...state.likedHomes, ...payload.properties];
          }

          if (
            state.likedHomes.length - state.numberOfHomesUnliked >=
            payload.count
          ) {
            state.hasMoreLikedHomes = false;
          } else state.hasMoreLikedHomes = true;
        },
      )
      .addCase(getUsersLikedHomes.rejected, (state) => {
        state.isLoadingLikedHomes = false;
      })
      .addCase(updateUserDetails.pending, (state) => {
        state.isUpdatingUserDetails = true;
      })
      .addCase(updateUserDetails.fulfilled, (state, { payload }) => {
        state.user = { ...state.user, ...payload };
        state.isUpdatingUserDetails = false;
      })
      .addCase(updateUserDetails.rejected, (state) => {
        state.isUpdatingUserDetails = false;
      });
  },
});

// Define the checkAppPlatformCookie function
const checkAppPlatformCookie = () => {
  if (typeof document !== "undefined") {
    const cookies = document.cookie.split("; ");
    const appPlatformCookie = cookies.find((cookie) =>
      cookie.startsWith("app-platform="),
    );
    if (appPlatformCookie) {
      const value = appPlatformCookie.split("=")[1];
      return value === "iOS App Store";
    }
  }
  return false;
};

// Create the thunk action
export const initializeIsRunningInNativeApp = createAsyncThunk(
  "user/initializeIsRunningInNativeApp",
  async (_, { dispatch }) => {
    // Wait for a short delay (e.g., 100ms) to ensure cookies are available
    await new Promise((resolve) => setTimeout(resolve, 100));
    const isRunningInNativeApp = checkAppPlatformCookie();
    dispatch(setIsRunningInNativeApp(isRunningInNativeApp));
  },
);

export const {
  setUserToken,
  setUserInfo,
  removeUserInfo,
  changeStatusInLikedHome,
  resetNumberOfHomesUnliked,
  setIsRunningServiceWorker,
  setIsRunningInNativeApp,
  setCardsOnboardingComplete,
  setOnboardingSlideshowComplete,
  resetTours,
  setEditProfilePromptLastShown,
  setFirstPhotoPromptLastShown,
} = userSlice.actions;
export default userSlice.reducer;
