const min = 500;
const max = 12000;
const defaultValues = [400, 800];
const steps = [
  { gt: 500, step: 10 },
  { gt: 5000, step: 10 },
];

let storedValues = null;

const values = () => {
  if (storedValues) return storedValues;
  const rangeValues = [];
  let currentValue = min;

  for (let i = 0; i < steps.length; i++) {
    const { gt, step } = steps[i];
    let nextThreshold = i < steps.length - 1 ? steps[i + 1].gt : max;
    while (currentValue < nextThreshold) {
      rangeValues.push(currentValue);
      currentValue += step;
    }
  }

  // Ensure the final value is the specified maximum (10,000,000)
  rangeValues.push(max);
  storedValues = rangeValues;
  return rangeValues;
};

const square_feet = {
  min,
  max,
  defaultValues,
  steps,
  values: values(),
};

export default square_feet;
