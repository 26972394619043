import React from "react";
import { Helmet } from "react-helmet-async";
import AccordionCustom from "@src/components/accordion/AccordionCustom";
import BackButtonLink from "@src/components/backButtonLink/BackButtonLink.jsx";

function FAQs() {
  const accordionData = [
    {
      title: "What is Unlisted?",
      content: `Unlisted is a new way for people to gather and share information so you can feel confident, prepared, and (dare we say it) excited about homeownership. We are not here because we want you to sell your home; we have no vested interest in your financial plans or transactions.
      
          Unlisted exists because we’re a team of homeowners who are frustrated with the stress, fear, and uncertainty that comes with buying and owning a home. The tools and features on Unlisted aim to help you create more certainty and possibility as you navigate homeownership.`,
    },
    {
      title: "What’s the catch?",
      content: `There isn’t one. Unlisted is not a real estate agency or brokerage; we have no vested interest in if or when you buy or sell a home. Unlisted exists to add joy and satisfaction to the process of buying and owning a home — a process that, right now, makes most people cry. The tools and features we’ve built are designed to give you more choice, more information, and more possibility as you navigate homeownership.`,
    },
    {
      title: "Is Unlisted run by real estate agents?",
      content: `No, Unlisted is run by a team of innovators, technologists, and homeowners like you who have a passion for adding more joy and satisfaction to the process of buying and selling homes. We do frequently consult with our real estate partners and friends to understand the market and real estate process, but we are not real estate agents, and we’re not here to get you to sell your home.`,
    },
    {
      title:
        "Why did Unlisted send me mail about someone wanting to buy my house?",
      content: `Congratulations — a real person looking for their next home loves your house!
      
          Unlisted sent you mail on behalf of a verified buyer who is looking to purchase their next home. It’s important to note that we never send mail on behalf of developers or flippers; your note is from a real person who is interested in your particular home. 
          
          You can securely and anonymously reply directly to them — even if you’re in your forever home — by using the invitation code included in your mail.`,
    },
    {
      title: "I’m a homeowner — why would I use Unlisted?",
      content: `There’s a lot of information about your home online: dozens of real estate websites have photos and details about your home — but you don’t control any of it. Unlisted is the first website that empowers homeowners like you to control and update the information available about your home in your property profile.
      
          With your Unlisted property profile, you can upload photos that show off the best details in your home, speak to your home’s unique perks (like a cozy breakfast nook or a beautiful oak tree in the backyard), and update any information that’s out of date.`,
    },
  ];
  return (
    <>
      <Helmet>
        <title>FAQ Page</title>
        <meta name="description" content="FAQ" />
      </Helmet>
      <BackButtonLink className="pt-24" />
      <div className="mx-auto w-full px-6 pb-12 pt-32 md:max-w-4xl">
        <h1 className="py-2 font-attention text-3xl text-gray-900">FAQs</h1>
        <AccordionCustom data={accordionData} />
      </div>
    </>
  );
}

export default FAQs;
