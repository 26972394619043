import React from "react";
import { MdFlag } from "react-icons/md";

function PropertyProfileHeadline({ property, className }) {
  return (
    <h2 className={`${className} flex items-center gap-2`}>
      {property.owner_headline ? (
        <p>{property.owner_headline}</p>
      ) : (
        <>
          <p className="capitalize">{`${property?.address_line_1?.toLowerCase()}`}</p>
        </>
      )}
    </h2>
  );
}

export default PropertyProfileHeadline;
