import React from "react";
import { MdHome } from "react-icons/md";
import { motion } from "framer-motion";

function SearchSpinner({ label = "Finding properties..." }) {
  return (
    <motion.div
      initial={{ y: 100, opacity: 0 }}
      animate={{ y: 0, opacity: 1 }}
      transition={{
        duration: 0.3,
        damping: 30,
        ease: "easeOut",
        delay: 0.1,
      }}
      className="flex flex-col items-center gap-6"
    >
      <div className="relative">
        <div className="h-36 w-36 animate-spin rounded-full border-2 border-neutral-100 border-r-primary-500/60 bg-white"></div>
        <MdHome className="absolute left-1/2 top-1/2 h-12 w-12 -translate-x-1/2 -translate-y-1/2 fill-primary-500/70" />
      </div>

      <span className="animate-pulse text-sm text-neutral-500">{label}</span>
    </motion.div>
  );
}

export default SearchSpinner;
