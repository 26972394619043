import React, { useEffect, useState } from "react";
import { NavLink, useSearchParams } from "react-router-dom";
import { MdArrowLeft, MdChevronLeft } from "react-icons/md";

function BackButtonLink({ className }) {
  const [searchParams, setSearchParams] = useSearchParams();
  const [backButtonLink, setBackButtonLink] = useState(null);
  useEffect(() => {
    //see if the params have a backTo parameter
    const backTo = searchParams.get("backTo");

    if (backTo) {
      setBackButtonLink(backTo);
    }
  }, [searchParams]);

  return (
    <>
      {backButtonLink ? (
        <div
          className={`sticky top-0 z-20 mx-auto flex w-full max-w-4xl justify-center ${className}`}
        >
          <NavLink
            to={`/${backButtonLink}`}
            className="absolute left-2 flex items-center rounded-lg bg-white/60 p-2 px-4 pl-1.5 font-attention text-lg transition-all md:hover:bg-neutral-200/60"
          >
            <MdChevronLeft className="h-6 w-6 translate-y-0.5" />
            <span>Back</span>
          </NavLink>
        </div>
      ) : null}
    </>
  );
}

export default BackButtonLink;
