import axios from "axios";
import { AUTHENTICATION_TOKEN, USER_ID } from "./constants";

const axiosClient = axios.create();

axiosClient.defaults.baseURL = import.meta.env.VITE_API_URL;

axiosClient.defaults.headers = {
  "Content-Type": "application/json",
  Accept: "application/json",
};

axiosClient.interceptors.request.use((request) => {
  const authToken = JSON.parse(localStorage.getItem(AUTHENTICATION_TOKEN));
  if (authToken) {
    request.headers.Authorization = `Token ${authToken}`;
  }
  return request;
});

const feVersion = { fe_version: "new" };

export function getRequest({ url, params = {} }) {
  return axiosClient
    .get(`${url}`, { params: { ...params, ...feVersion } })
    .then((response) => response);
}

export function postRequest({ url, payload, options = {} }) {
  return axiosClient
    .post(`${url}`, { ...payload, ...feVersion }, options)
    .then((response) => response);
}

export function patchRequest({ url, payload, params = {} }) {
  return axiosClient
    .patch(`${url}`, payload, { params: { ...params, ...feVersion } })
    .then((response) => response);
}

export function deleteRequest({ url, params = {} }) {
  return axiosClient
    .delete(`${url}`, { params: { ...params, ...feVersion } })
    .then((response) => response);
}
