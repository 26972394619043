import React, { useState } from "react";
import { MdFlag, MdMarkunreadMailbox } from "react-icons/md";
import { useSelector } from "react-redux";
import SignInModal from "@src/components/signInModal/SignInModal.jsx";
import ClaimHomeVerificationModal from "@src/components/propertyProfile/propertyProfileContent/components/claimHomeVerificationModal/ClaimHomeVerificationModal.jsx";
import { SIGNIN_MODAL_TYPE } from "@src/utils/constants.js";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

function PropertyProfileCallsToAction({ property }) {
  const navigate = useNavigate();
  const isLoggedIn = useSelector((state) => state.user.isLoggedIn);
  const loggedInUser = useSelector((state) => state.user.user);
  const homes = useSelector((state) => state.user.homes);
  const [signinModalType, setSigninModalType] = useState("");
  const [redirectUrl, setRedirectUrl] = useState("");
  const [isClaimHomeModalVisible, setIsClaimHomeModalVisible] = useState(false);

  const onClickSignModalOverlay = () => {
    setSigninModalType("");
    setRedirectUrl("");
  };

  const onClickClaimHomeModalOverlay = () => {
    setIsClaimHomeModalVisible(false);
    setRedirectUrl("");
  };

  const handleClaimHome = () => {
    if (!isLoggedIn) {
      setRedirectUrl(`/profile/${property.id}?claimHome=true`);
      setSigninModalType(SIGNIN_MODAL_TYPE.PROPERTY);
      return;
    }
    setIsClaimHomeModalVisible(true);
  };

  const handleChat = () => {
    setRedirectUrl(`/conversations/create/${property?.id}`);
    if (!isLoggedIn) {
      setSigninModalType(SIGNIN_MODAL_TYPE.CHAT);
      return;
    }

    if (property?.owner_id !== loggedInUser.id)
      navigate(`/conversations/create/${property?.id}`);
    else toast.error("You own this property!");
  };

  return (
    <div className="@container w-full">
      <div className="@xl:flex-row flex w-full flex-col items-center justify-center overflow-hidden">
        <div className="flex w-full flex-1 flex-col items-center justify-center p-5">
          <h3 className="mb-3 text-center font-attention text-neutral-800">
            Interested in this home?
          </h3>
          <button
            onClick={handleChat}
            className="flex w-full max-w-fit items-center justify-center gap-2.5 whitespace-nowrap rounded-lg bg-primary-500 px-12 py-3.5 font-attention text-white"
          >
            {/*<MdOutlineChat className="shrink-0 translate-y-0.5" />{" "}*/}
            <MdMarkunreadMailbox className="shrink-0" /> Contact owner
          </button>
        </div>

        {((!isLoggedIn && !property?.owner_id) ||
          (isLoggedIn && !property?.owner_id && homes.length < 1)) && (
          <>
            <div className="@xl:w-14 relative mt-1 flex w-full items-center justify-center">
              <div className="@xl:rotate-90 absolute top-1/2 h-[1px] w-full rotate-0 bg-neutral-300"></div>
              <span className="relative flex h-10 w-10 items-center justify-center rounded-full bg-white font-attention text-sm text-neutral-800 ring-1 ring-neutral-300">
                OR
              </span>
            </div>
            <div className="flex w-full flex-1 flex-col items-center p-5">
              <h3 className="mb-3 text-center font-attention text-neutral-800">
                Do you own this home?
              </h3>
              {/*<button className="flex w-full items-center justify-center gap-2.5 whitespace-nowrap rounded-lg bg-[#d3c295] px-12 py-3.5 font-attention text-[#4b4535]">*/}
              {/*  <MdOutlineFlag className="shrink-0" /> Claim Home*/}
              {/*</button>*/}
              <button
                onClick={handleClaimHome}
                className="flex w-full max-w-fit items-center justify-center gap-2.5 whitespace-nowrap rounded-lg bg-[#e3e3e3] px-12 py-3.5 font-attention text-[#333333] ring-1 ring-[#d4d3d2]"
              >
                <MdFlag className="shrink-0" /> Claim home
              </button>
            </div>
          </>
        )}
      </div>
      {signinModalType ? (
        <SignInModal
          isOpen={!!signinModalType}
          handleClose={onClickSignModalOverlay}
          redirectTo={redirectUrl}
          type={signinModalType}
        />
      ) : null}
      {isClaimHomeModalVisible ? (
        <ClaimHomeVerificationModal
          open={isClaimHomeModalVisible}
          onClickOverlay={onClickClaimHomeModalOverlay}
          propertyId={property?.id}
          property={property}
        />
      ) : null}
    </div>
  );
}

export default PropertyProfileCallsToAction;
