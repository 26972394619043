import React, { useState } from "react";
import { MdCheck } from "react-icons/md";
import * as RadioGroup from "@radix-ui/react-radio-group";

function RadioGroupInput({ value, onChange, options, allowDeselect }) {
  const [selectedValue, setSelectedValue] = useState(value);

  const handleValueChange = (newValue) => {
    // If the selected value is clicked again, clear the selection
    const updatedValue = selectedValue === newValue ? null : newValue;
    setSelectedValue(updatedValue);
    onChange(updatedValue);
  };

  return (
    <div className="mx-auto w-full max-w-3xl @container">
      <RadioGroup.Root
        className="grid w-full grid-cols-1 gap-3 @xs:grid-cols-2"
        value={selectedValue}
        aria-label="View density"
        onValueChange={handleValueChange}
      >
        {options.map(({ id, title, description }, index) => (
          <RadioGroup.Item
            key={index}
            className={`relative flex w-full items-start rounded-lg border border-primary-500 p-4 font-attention text-lg text-neutral-900 transition-colors focus:outline-none focus-visible:outline-2 focus-visible:outline-offset-[3px] focus-visible:outline-primary-500 data-[state=checked]:bg-primary-500 data-[state=checked]:text-white sm:hover:bg-neutral-200/80 sm:data-[state=checked]:hover:bg-sage-700/70`}
            value={id} // Ensure the value is a string
            onClick={() => handleValueChange(id)}
          >
            <div className="flex flex-col items-start gap-1">
              {title && <h3 className="font-attention text-xl">{title}</h3>}
              {description && (
                <p className="text-pretty text-left font-content text-sm">
                  {description}
                </p>
              )}
            </div>

            <RadioGroup.Indicator className="absolute right-3 top-3 flex items-center justify-center rounded-full p-1 ring-1 ring-white/40">
              <MdCheck className="h-4 w-4 fill-white" />
            </RadioGroup.Indicator>
          </RadioGroup.Item>
        ))}
      </RadioGroup.Root>
    </div>
  );
}

export default RadioGroupInput;
