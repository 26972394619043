import React, {
  useEffect,
  useState,
  useRef,
  forwardRef,
  useImperativeHandle,
} from "react";
import { useMapsLibrary } from "@vis.gl/react-google-maps";
import {
  setIsFilterSearching,
  setIsTraditionalSearchingFailure,
} from "@src/store/features/search/slice.js";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { formatGoogleAPIPlace } from "@src/utils/misc.js";
import { IoMdCloseCircle } from "react-icons/io";

function FilterSearchAutocomplete({ onApplyFilter }) {
  const inputRef = useRef(null);

  const {
    isTraditionalSearchingFailure,
    isFilterSearching,
    currentDisplayProperty,
    lastSearchValues,
  } = useSelector((state) => state.search);
  const dispatch = useDispatch();

  const [searchParams, setSearchParams] = useSearchParams();

  const [localTraditionalSearchString, setLocalTraditionalSearchString] =
    useState(lastSearchValues?.traditionalSearchString || "");

  const [localSelectedPlace, setLocalSelectedPlace] = useState(null);

  const onPlaceSelected = (place) => {
    if (!place?.formatted_address) return;
    setLocalSelectedPlace(place);
  };

  const onSearchQueryChange = async (e) => {
    dispatch(setIsTraditionalSearchingFailure(false));
    setLocalTraditionalSearchString(e.target.value);
  };

  useEffect(() => {
    dispatch(setIsTraditionalSearchingFailure(false));
  }, []);

  useEffect(() => {
    dispatch(setIsFilterSearching(false));
  }, [isTraditionalSearchingFailure]);

  useEffect(() => {
    if (currentDisplayProperty) {
      setSearchParams({ showing: currentDisplayProperty.id });
    }
  }, [currentDisplayProperty]);

  useEffect(() => {
    if (localSelectedPlace) {
      setLocalTraditionalSearchString(localSelectedPlace.formatted_address);
      onApplyFilter({
        valuesToSave: {
          selectedPlace: formatGoogleAPIPlace({ place: localSelectedPlace }),
          traditionalSearchString: localSelectedPlace.formatted_address,
        },
        valuesToSearch: formatGoogleAPIPlace({ place: localSelectedPlace }),
      });
    }
  }, [localSelectedPlace]);

  return (
    <>
      <div className="flex w-full flex-col gap-14">
        <div className="z-10 mt-8 w-full rounded-xl bg-white p-5 pb-6 pt-3">
          <header
            className={`${isFilterSearching && "opacity-30"} mb-3 flex flex-row justify-start`}
          >
            <label className="font-semibold text-gray-600">
              Change Location
            </label>
          </header>
          <div className="rounded-md ring-0 ring-transparent transition-shadow focus-within:ring-2 focus-within:ring-[#8E9991]/40 focus-within:ring-offset-2">
            <div className="relative w-full overflow-hidden rounded-md">
              {isFilterSearching && (
                <div className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2">
                  <div className="h-6 w-6 animate-spin rounded-full border border-2 border-black/10 border-t-sage-700 transition duration-100" />
                </div>
              )}

              <PlaceAutocomplete
                textValue={localTraditionalSearchString}
                className={`${isFilterSearching && "pointer-events-none opacity-30"} inline-block w-full rounded-md border border-[#8E9991] p-3 pr-12 font-content outline-0 transition-all focus:border-[#8E9991]/50`}
                onPlaceSelect={onPlaceSelected}
                onChange={(e) => onSearchQueryChange(e)}
                ref={inputRef}
              />
              {localTraditionalSearchString && (
                <button
                  data-close
                  onClick={() => {
                    setLocalTraditionalSearchString("");
                    dispatch(setIsTraditionalSearchingFailure(false));
                    if (inputRef.current) {
                      inputRef.current.disableAutocomplete(); // Clear the autocomplete suggestions and disable autocomplete
                      setTimeout(() => {
                        inputRef.current.enableAutocomplete(); // Re-enable autocomplete after hiding the dropdown
                        inputRef.current.focusInput(); // Focus the input
                      }, 150); // Adjust delay if necessary
                    }
                  }}
                  className={`${isFilterSearching && "pointer-events-none opacity-30"} absolute right-0 top-0 flex h-12 w-12 items-center justify-center`}
                >
                  <IoMdCloseCircle className="h-6 w-6 fill-neutral-300" />
                </button>
              )}
            </div>
          </div>
          {isTraditionalSearchingFailure && (
            <span className="block w-full p-1.5 px-1 text-sm text-red-500">
              Sorry, your search returned no results. Please try again.
            </span>
          )}
        </div>
      </div>
    </>
  );
}

export default FilterSearchAutocomplete;

export const PlaceAutocomplete = forwardRef(
  ({ onPlaceSelect, className, onChange, textValue, placeholder }, ref) => {
    const [placeAutocomplete, setPlaceAutocomplete] = useState(null);
    const inputRef = useRef(null);
    const places = useMapsLibrary("places");
    const [isAutocompleteEnabled, setIsAutocompleteEnabled] = useState(true);

    useEffect(() => {
      if (inputRef.current) {
        inputRef.current.focus(); // Focus the input when the component mounts
      }
    }, []);

    useImperativeHandle(ref, () => ({
      disableAutocomplete() {
        setIsAutocompleteEnabled(false);
        if (placeAutocomplete) {
          placeAutocomplete.setOptions({ types: [] }); // Disable autocomplete
        }
        inputRef.current.value = ""; // Clear the input value
        const event = new Event("input", { bubbles: true }); // Simulate an input event
        inputRef.current.dispatchEvent(event); // Trigger the input change event
      },
      enableAutocomplete() {
        setIsAutocompleteEnabled(true);
        if (placeAutocomplete) {
          placeAutocomplete.setOptions({ types: ["(regions)"] }); // Re-enable autocomplete
        }
      },
      focusInput() {
        inputRef.current?.focus(); // Focus the input
      },
    }));

    useEffect(() => {
      if (!places || !inputRef.current) return;

      const autocomplete = new places.Autocomplete(inputRef.current, {
        fields: [
          "type",
          "geometry",
          "name",
          "formatted_address",
          "address_components",
        ],
        componentRestrictions: { country: "us" },
        types: [
          "administrative_area_level_1",
          "locality",
          "postal_code",
          "premise",
          "subpremise",
        ], // Default types
      });

      setPlaceAutocomplete(autocomplete);
      return () => {
        // Cleanup the listener when component unmounts
        if (autocomplete) {
          window.google.maps.event.clearInstanceListeners(autocomplete);
        }
      };
    }, [places]);

    useEffect(() => {
      if (!placeAutocomplete) return;

      placeAutocomplete.addListener("place_changed", () => {
        onPlaceSelect(placeAutocomplete.getPlace());
      });
    }, [onPlaceSelect, placeAutocomplete]);

    const handleInputChange = (e) => {
      const inputValue = e.target.value;
      onChange(e);

      if (placeAutocomplete) {
        const looksLikeAddress = /^\d+[a-zA-Z\d\-]* /.test(inputValue);
        placeAutocomplete.setOptions({
          types: looksLikeAddress
            ? ["address"]
            : [
                "administrative_area_level_1",
                "locality",
                "postal_code",
                "premise",
                "subpremise",
              ],
        });
      }
    };

    return (
      <input
        ref={inputRef} // Use the forwarded ref if available, or fallback to local ref
        value={textValue}
        onChange={handleInputChange}
        className={className}
        placeholder={placeholder ?? "Address, city, state, or zip"}
      />
    );
  },
);

// Adding the display name to the forwardRef component
PlaceAutocomplete.displayName = "PlaceAutocomplete";
