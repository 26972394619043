import { AUTHENTICATION_TOKEN } from "./constants";
import numeral from "numeral";
import { formatDistanceToNowStrict } from "date-fns";

export const getAuthTokenFromStorage = () => {
  const token = localStorage.getItem(AUTHENTICATION_TOKEN);
  if (token) {
    return JSON.parse(token);
  }
  return token;
};

export const dateTimeFormatter = (date) => {
  return formatDistanceToNowStrict(new Date(date), { addSuffix: true });
};

export const getFakeEstimatedPrice = (property) => {
  if (!property) return;
  // const formatToCurrency = (amount) => {
  //   return new Intl.NumberFormat("en-US", {
  //     style: "currency",
  //     currency: "USD",
  //     minimumFractionDigits: 0, // Ensures there are no decimals
  //   }).format(amount);
  // };

  property.estimated_price =
    Math.round((Math.random() * (650000 - 200000 + 1) + 200000) / 110000) *
    110000;
};

export const abbreviateNumber = (number) => {
  const formatValue = (value) => {
    let formatter = "0a";
    let formatted = numeral(value).format(formatter);
    formatted = formatted.replace("m", "M"); // Replace 'm' with 'M'
    return formatted;
  };
  return formatValue(number);
};

// Function to format numbers with commas every three digits
export const formatNumber = (number) => {
  if (typeof number === "number") number = number.toString();
  if (!number) return ""; // Handle empty value
  const parsed = parseFloat(number.replace(/,/g, "")); // Remove commas to format the raw number
  if (isNaN(parsed)) return ""; // In case the number is not valid
  return new Intl.NumberFormat().format(parsed);
};

export const unformatNumber = (formattedValue) => {
  return parseInt(formattedValue.replace(/,/g, ""));
};

export const formatGoogleAPIPlace = ({ place }) => {
  let searchData = {
    // exact_lat: place.geometry.location.lat(),
    // exact_long: place.geometry.location.lng(),
  };

  if (
    place.types.includes("street_address") ||
    place.types.includes("premise") ||
    place.types.includes("establishment") ||
    place.types.includes("subpremise")
  ) {
    searchData.address = place.formatted_address;

    place.address_components.forEach((component) => {
      if (
        component.types.includes("locality") ||
        component.types.includes("administrative_area_level_2")
      ) {
        searchData.city = component.long_name;
      } else if (component.types.includes("street_number")) {
        searchData.street_number = component.long_name;
      } else if (component.types.includes("administrative_area_level_1")) {
        searchData.state = component.short_name;
      } else if (component.types.includes("subpremise")) {
        searchData.unit_value = component.short_name;
      } else if (component.types.includes("route")) {
        searchData.street_name = component.short_name;
      } else {
        searchData[[component.types[0]]] = component.long_name;
      }
    });
  } else if (place.types.includes("locality")) {
    place.address_components.forEach((component) => {
      if (component.types.includes("locality")) {
        searchData.city = component.long_name;
      } else if (component.types.includes("administrative_area_level_1")) {
        searchData.state = component.short_name;
      } else {
        searchData[[component.types[0]]] = component.long_name;
      }
    });
  } else if (place.types.includes("administrative_area_level_1")) {
    place.address_components.forEach((component) => {
      if (component.types.includes("administrative_area_level_1")) {
        searchData.state = component.short_name;
      } else {
        searchData[[component.types[0]]] = component.long_name;
      }
    });
  } else if (place.types.includes("postal_code")) {
    place.address_components.forEach((component) => {
      if (component.types.includes("postal_code")) {
        searchData.zip = component.short_name;
      } else {
        searchData[[component.types[0]]] = component.long_name;
      }
    });
  }

  return searchData;
};

export const getInitials = ({ firstName, lastName }) => {
  const firstInitial = firstName ? firstName?.charAt(0)?.toUpperCase() : "";
  const lastInitial = lastName ? lastName?.charAt(0)?.toUpperCase() : "";

  return `${firstInitial}${lastInitial}`;
};

export const isEmptyAfterTrim = (text) => /^\s*$/.test(text);
