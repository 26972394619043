import { useLocation } from "react-router-dom";
import { MdMap, MdViewList } from "react-icons/md";
import CardsViewIcon from "@src/assets/svgs/CardsViewIcon";
import DiscoverTab from "./DiscoverTab.jsx";

function DiscoverTabs() {
  const location = useLocation();
  const sliderPosition =
    location.pathname === "/discover/cards"
      ? "left-0"
      : location.pathname === "/discover/map"
        ? "left-1/3"
        : "left-2/3";

  return (
    <div className="relative flex w-full flex-row border-t border-gray-300 font-attention text-lg">
      <div
        className={`absolute left-0 top-0 h-1 w-1/3 rounded-bl-lg rounded-br-lg bg-[#B2A585] ${sliderPosition} transition-all`}
      ></div>
      <DiscoverTab to="/discover/cards" icon={CardsViewIcon}>
        Cards
      </DiscoverTab>
      <DiscoverTab to="/discover/map" icon={MdMap}>
        Map
      </DiscoverTab>
      <DiscoverTab to="/discover/list" icon={MdViewList}>
        List
      </DiscoverTab>
    </div>
  );
}

export default DiscoverTabs;
