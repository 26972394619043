import React from 'react'

function CardsViewIcon({className}) {
    return (
        <svg className={className} xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 19.2704 22" fill="currentColor">
            <rect x="2.8858" y="5.424" width="21.0322" height="13.152" rx="3.4751"
                  transform="translate(-3.1451 21.6842) rotate(-78.2767)"/>
            <path
                d="M4.3985,17.3225,7.2407,3.6269H4.391A2.3167,2.3167,0,0,0,2.0743,5.9436v10.142A2.3126,2.3126,0,0,0,4.315,18.3947,4.636,4.636,0,0,1,4.3985,17.3225Z"
                transform="translate(-2.0743 -1)"/>
        </svg>
    )
}

export default CardsViewIcon
