export const init = () => {
  console.log("Initializing Service Worker...");

  if ("serviceWorker" in navigator) {
    navigator.serviceWorker
      .register("/sw.js")
      .then((registration) => {
        console.log("Service Worker registered successfully:", registration);

        // --- 1. Periodic update checking ---
        const enablePeriodicUpdates = true; // Toggle periodic updates

        if (enablePeriodicUpdates) {
          setInterval(
            () => {
              console.log("Service Worker - setInterval registration.update()");
              registration.update();
            },
            60 * 60 * 1000,
          ); // Check for updates every hour
        }

        // --- 2. Focus-based update checking ---
        const enableFocusUpdates = true; // Toggle updates on window focus

        if (enableFocusUpdates) {
          window.addEventListener("focus", () => {
            console.log("Service Worker - window focus registration.update()");
            registration.update();
          });
        }

        // --- 3. Lifecycle: Detecting when a new SW is found and installed ---
        const enableUpdateNotification = true; // Notify or auto-refresh on new SW

        if (enableUpdateNotification) {
          registration.addEventListener("updatefound", () => {
            const newWorker = registration.installing;
            console.log("A new Service Worker has been found:", newWorker);

            // Show modal as soon as we know a new SW is installing
            showUpdateModal();

            newWorker.addEventListener("statechange", () => {
              if (
                newWorker.state === "installed" &&
                navigator.serviceWorker.controller
              ) {
                console.log("New Service Worker is installed and waiting.");
                // Option: Notify user or auto-refresh
                handleNewServiceWorker(registration);
              }
            });
          });
        }

        // --- 4. Automatically refresh the page when a new SW activates ---
        const enableAutoRefresh = true; // Auto-refresh when the new SW takes control

        if (enableAutoRefresh) {
          navigator.serviceWorker.addEventListener("controllerchange", () => {
            console.log("New Service Worker is now controlling the page.");
            limitAutoReload(); // Call the limiter function to control reloads
          });
        }
      })
      .catch((error) => {
        console.error("Service Worker registration failed:", error);
      });
  }
};

// --- Limit the auto-refresh to once per minute ---
function limitAutoReload() {
  const lastServiceWorkerTriggeredReloadTime = localStorage.getItem(
    "lastServiceWorkerTriggeredReloadTime",
  );
  const now = Date.now();
  const oneMinute = 60 * 1000; // 1 minute in milliseconds

  if (
    !lastServiceWorkerTriggeredReloadTime ||
    now - lastServiceWorkerTriggeredReloadTime > oneMinute
  ) {
    console.log("Page reloading due to new Service Worker activation...");
    localStorage.setItem("lastServiceWorkerTriggeredReloadTime", now);
    window.location.reload(); // Reload the page
  } else {
    console.log("Skipping reload; too soon since last reload.");
    hideUpdateModal(); // Hide the modal if reload is skipped
    console.log(
      "lastServiceWorkerTriggeredReloadTime",
      lastServiceWorkerTriggeredReloadTime,
    );
    console.log("now", now);
  }
}

// --- 5. Handle new Service Worker: notify user or refresh ---
function handleNewServiceWorker(registration) {
  // Option 1: Notify user and give them control to refresh manually
  // showUpdateNotification(); // Example: Show a toast/notification prompting a reload

  // Option 2: Automatically force the new SW to take control and refresh the page
  if (registration.waiting) {
    registration.waiting.postMessage({ type: "SKIP_WAITING" });
  }
}

// --- Show Full-Screen Modal to Notify the User ---
function showUpdateModal() {
  // Create the modal elements
  const modalDiv = document.createElement("div");
  modalDiv.id = "update-modal";
  modalDiv.style.position = "fixed";
  modalDiv.style.top = "0";
  modalDiv.style.left = "0";
  modalDiv.style.width = "100vw";
  modalDiv.style.height = "100vh";
  modalDiv.style.backgroundColor = "rgba(0, 0, 0, 0.8)"; // Semi-transparent background
  modalDiv.style.color = "white";
  modalDiv.style.display = "flex";
  modalDiv.style.flexDirection = "column";
  modalDiv.style.justifyContent = "center";
  modalDiv.style.alignItems = "center";
  modalDiv.style.zIndex = "9999"; // Ensure it covers everything

  // Modal content
  const modalContent = `
    <div style="text-align: center;">
      <h1 style="margin-bottom: 20px;">Updating the App<br>Please stand by...</h1>
    </div>
  `;
  modalDiv.innerHTML = modalContent;

  // Append the modal to the body
  document.body.appendChild(modalDiv);
}

// --- Hide the Update Modal ---
function hideUpdateModal() {
  const modalDiv = document.getElementById("update-modal");
  if (modalDiv) {
    modalDiv.remove();
    console.log("Update modal hidden.");
  }
}

export default { init };
